import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { get } from "lodash";
import { reportShowModal } from "../common/function";
import { useId } from "../hooks/useId";
import FocusTrap from "focus-trap-react";
import { useOnEsc } from "../hooks/useOnEsc";
import { useDispatch } from "react-redux";
import { closeModal } from "../redux/actions";

function Modal({ conf }) {
  const modalRef = useRef();
  const headerId = useId();
  const descriptionId = useId();
  const dispatch = useDispatch();

  useOnEsc({
    onEscPressed: () => dispatch(closeModal()),
  });

  useEffect(() => {
    reportShowModal(get(conf, "title", ""), get(conf, "text", ""));

    if (modalRef.current) {
      modalRef.current.focus();
    }
  }, [conf]);

  return (
    <ModalStyle>
      <FocusTrap>
        <div
          ref={modalRef}
          className="modal-container"
          role="dialog"
          tabindex="0"
          aria-modal="true"
          aria-labelledby={headerId}
          aria-describedby={descriptionId}
        >
          {get(conf, "title", "") && (
            <div className="modal-title" id={headerId}>
              {get(conf, "title", "")}
            </div>
          )}
          {get(conf, "text", "") && (
            <div className="modal-text" id={descriptionId}>
              {get(conf, "text", "")}
            </div>
          )}
          <div className="button-container">
            {get(conf, "button1", "") && (
              <button onClick={get(conf, "button1.onClick", "")}>
                {get(conf, "button1.text", "")}
              </button>
            )}
            {get(conf, "button2", "") && (
              <button onClick={get(conf, "button2.onClick", "")}>
                {get(conf, "button2.text", "")}
              </button>
            )}
          </div>
        </div>
      </FocusTrap>
    </ModalStyle>
  );
}

export const ModalStyle = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 5;
  top: 0;
  left: 0;
  background-color: #000000a1;
  .modal-container {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 77%;
    box-shadow: 2px 2px 4px #424242;
    padding: 20px;
    border-radius: 20px;
  }
  .modal-title {
    font-size: 22px;
    font-weight: bold;
    margin: 16px 0;
    margin-top: 0;
  }
  .modal-text {
    margin: 15px 0;
  }
  .button-container {
    display: flex;
    justify-content: space-between;
  }
  button {
    background: none;
    cursor: pointer;
    border: none;
    font-weight: bold;
    font-size: 16px;
  }
  .main-button {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .main-title {
    font-size: 29px;
  }
  .close {
    margin: 5px;
  }
  form {
    padding: 45px 50px;
  }
  @media only screen and (min-width: 980px) {
    .modal-container {
      width: 300px;
    }
  }
`;

export default Modal;
