import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, compose, applyMiddleware } from "redux";
import reducer from "./redux/reducer";
import thunk from "redux-thunk";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { reportError } from "./common/function";
import {initializeApp} from 'firebase/app';
import { getAnalytics } from "firebase/analytics";

let firebaseConfig;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  firebaseConfig = {
    apiKey: 'AIzaSyBoHI6z1uFYtt2YJ5EuwFKHwXv4ODTCHGM',
    projectId: 'bambizz-dev',
    appId: '1:384417247635:web:a8f7c91cdb608438bff866',
    measurementId: 'G-M2JVJ07X36',
  };
} else {
  firebaseConfig = {
    apiKey: 'AIzaSyD6MN-XvDcdBMFiZhsdrM4Rj5xht0MpkDE',
    projectId: 'bambizz',
    appId: '1:254590358355:web:2a32494748a0e663ce07a6',
    measurementId: 'G-PGTEBBJNVT',
  };
}

window.firebaseConfig = firebaseConfig;

const app = initializeApp(firebaseConfig);
window.analytics = getAnalytics(app);

try {
  //save gclid
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) === "gclid") {
      window.gclid = decodeURIComponent(pair[1]);
    }
  }
} catch (e) {}

if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
  window.console.log = () => null;
  window.console.error = () => null;
  window.console.warn = () => null;
}
try {

  const store = createStore(
    reducer,
    compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f
    )
  );

  let root = document.getElementById("root");
  document.getElementById("root").innerHTML = "";
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    root
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
} catch (err) {
  reportError(err);
}
