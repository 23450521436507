import React from "react";
import { ICHome, ICLogo } from "../Icons";
import { ARTICLES } from "./constants";
import { LanguageContext } from "../../language/lang";
import { IconA } from "../Components";
import styled from "styled-components";

export default function Articles() {
  const lang = React.useContext(LanguageContext);
  const language = lang.id.toLowerCase();
  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    let listener = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  return (
    <PageStyle>
      <div className="page-container">
        <header className="page-header">
          {width < 980 && <ICLogo className="main-logo" />}
          <nav className="nav-panel">
            <IconA href="/" title={lang["Home"]}>
              <ICHome fill="#393b45" className="home-icon" />
            </IconA>
            <span>&gt;</span>
            <IconA
              href={"/" + language + "/articles/"}
              title={lang["articles"]}
            >
              {lang["articles"]}
            </IconA>
          </nav>
        </header>
        {ARTICLES.filter((article) => article.lang === language).map(
          (article) => (
            <IconA
              className="articles-link"
              href={"/" + language + "/articles/" + article.url}
              title={article.pageTitle}
            >
              <hr />
              <section>
                <header>
                  <h2>{article.pageTitle}</h2>
                  <p>{article.pageSubTitle}</p>
                  <p className="posted-time">{article.pagePostedDescription}</p>
                  <hr />
                  <img
                    alt=""
                    role="presentation"
                    src={article.pageImageUrl}
                    width={Math.min(width - 30, 600)}
                    height="600"
                    layout="responsive"
                  ></img>
                </header>
              </section>
            </IconA>
          )
        )}
      </div>
    </PageStyle>
  );
}

const PageStyle = styled.main`
  a {
    padding: 0;
  }

  .home-icon {
    height: 25px;
  }
`;
