import {
  apiRequest,
  showMessage,
  reportError,
} from "../common/function";
import getLang from "../language/lang";
import { navigateTo } from "../common/function";

export const ACTIONS = {
  CLOSE_MODAL: "CLOSE_MODAL",
  OPEN_MODAL: "OPEN_MODAL",
  HIDE_LOADER: "HIDE_LOADER",
  SHOW_LOADER: "SHOW_LOADER",
  GET_PRICES: "GET_PRICES",
  NONE: null,
  SEND_OFFER: "SEND_OFFER",
  CANCEL_OFFER: "CANCEL_OFFER",
  SEND_CONTACT_US: "SEND_CONTACT_US",
};

/**********GENERAL ACTIONS**********/

export const openModal = (conf) => {
  return async (dispatch, getState) => {
    dispatch({
      type: ACTIONS.OPEN_MODAL,
      payload: conf,
    });
  };
};

export const closeModal = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: ACTIONS.CLOSE_MODAL,
    });
  };
};

export const showLoader = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: ACTIONS.SHOW_LOADER,
    });
  };
};

export const hideLoader = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: ACTIONS.HIDE_LOADER,
    });
  };
};

export const sendContactUs = (fields) => {
  return async (dispatch, getState) => {
    try {
      dispatch(showLoader());
      let res = await apiRequest("/apiwebsite/send-contact-us", fields, "POST");
      dispatch(hideLoader());
      showMessage(
        dispatch,
        getLang()["Message Send"],
        getLang()["We will contact you soon"]
      );
      navigateTo("/");
      if (res.success) {
        dispatch({
          type: ACTIONS.SEND_CONTACT_US,
        });
      } else {
        showMessage(dispatch, getLang()["Error"], getLang()[res.code]);
      }
    } catch (err) {
      reportError(err);
      dispatch(hideLoader());
    }
  };
};
