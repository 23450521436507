import React, { useState, useEffect } from "react";
import styled from "styled-components";
import getLang from "../language/lang";
import { ICWhatsApp } from "./Icons";

const DISABLE_WHATSAPP = true;

function WhatsAppButton({ mobile }) {
  const [pageOnly, setpageOnly] = useState(false);
  useEffect(() => {
    setpageOnly(window.location.pathname.indexOf("page-only") !== -1);
  }, []);

  if(DISABLE_WHATSAPP) return null;
  
  return pageOnly ? null : (
    <WhatsAppButtonStyle
      href="https://wa.me/message/F3LOFZWGV7PQO1"
      target="_blank"
    >
      <ICWhatsApp />
      {!mobile && <div className="text">{getLang()["need-help-qs"]}</div>}
    </WhatsAppButtonStyle>
  );
}

export const WhatsAppButtonStyle = styled.a`
  position: fixed;
  bottom: 15px;
  left: 10px;
  background: #00e676;
  border-radius: 50px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 6px 2px lightgrey;
  .text {
    display: none;
  }
  @media only screen and (min-width: 980px) {
    width: 170px;
    justify-content: space-evenly;
    color: white;
    text-decoration: none;
    .text {
      display: block;
      color: white;
    }
  }
`;

export default WhatsAppButton;
