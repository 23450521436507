import React, { useEffect } from "react";
import { ICCheck } from "./Icons";
import { get } from "lodash";

function OnSuccessYaadPage() {
  useEffect(() => {
    window.addEventListener(
      'message',
      (e, b) => {
        console.log('-----------------------------');
        console.log(e);
        console.log(b);
        console.log('-----------------------------');
      },
      false,
    );
    let url = window.location.href;
    let params = get(url.split("?"), "[1]", "");
    params = params.split("&");
    params = params.reduce((obj, param) => {
      obj[get(param.split("="), "[0]", "")] = decodeURIComponent(
        get(param.split("="), "[1]", "")
      );
      return obj;
    }, {});
    window.parent.postMessage({ id: "payment-success", ...params }, "*");
  }, []);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          backgroundColor: "#00c100",
          width: "100px",
          height: "100px",
          borderRadius: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ICCheck />
      </div>
    </div>
  );
}

export default OnSuccessYaadPage;
