import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { MdClose } from "react-icons/md";

import { useWindowSize } from "@react-hook/window-size";
import { IconButton, TitleH1 } from "./Components";
import { navigateTo, reportPageView, setSEO } from "../common/function";
import { LanguageContext } from "../language/lang";
import { get } from "lodash";

function DesktopApplication({ page }) {
  const lang = useContext(LanguageContext);
  const [width, height] = useWindowSize();
  const [time] = useState(Date.now());
  const [initialPage] = useState(page);
  const [currentPage, setCurrentPage] = useState(page);
  const linkEnd = window.gclid ? "gclid=" + window.gclid : "";
  useEffect(() => {
    if (width < 800) {
      if (window.gclid)
        window.location.replace("https://app.bambizz.com?" + linkEnd);
      else window.location.replace("https://app.bambizz.com");
    }
  }, [width]);
  useEffect(() => {
    reportPageView("/application");
  }, []);
  useEffect(() => {
    window.addEventListener("message", onMessage, false);
    return () => window.removeEventListener("message", onMessage);
  }, []);

  function onMessage(e) {
    try {
      if (get(e, "data.id", "") === "page-change" && e.data.path) {
        console.log(e.data.path);
        setCurrentPage(e.data.path.slice(1));
        setSEO("/application" + e.data.path);
        window.history.replaceState(
          {},
          null,
          "/" + lang.id.toLowerCase() + "/application" + e.data.path
        );
      }
      if (get(e, "data.id", "") === "page-redirect" && e.data.url) {
        console.log(e.data.url);
        window.location.href = e.data.url;
      }
    } catch (e) {}
  }

  console.log("currentPage", currentPage);
  return (
    <DesktopApplicationStyle height={height}>
      <div className="outher-tablet">
        <div className="speaker-tablet" />
        <IconButton
          onClick={() => navigateTo("/")}
          className="close"
          title={lang.close}
        >
          <MdClose />
        </IconButton>
        <iframe
          src={
            "https://app.bambizz.com/" +
            (initialPage || "") +
            "?lang=" +
            lang.id.toLowerCase() +
            (linkEnd ? "&" + linkEnd : "") +
            `&origin=${encodeURIComponent(window.location.origin)}` +
            "#" +
            time
          }
          className="i-frame"
          allow="geolocation 'self' https://app.bambizz.com"
          title={lang.bambizzWebApplication}
        />
        <div className="button-tablet" />
      </div>
      <img
        className="image-1"
        src="/images/desktop-1.png"
        alt=""
        role="presentation"
      />
      <img
        className="image-2"
        src="/images/desktop-2.png"
        alt=""
        role="presentation"
      />
      <img
        className="image-3"
        src="/images/desktop-3.png"
        alt=""
        role="presentation"
      />
      <img
        className="image-4"
        src="/images/desktop-4.png"
        alt=""
        role="presentation"
      />
      <img
        className="image-5"
        src="/images/desktop-5.png"
        alt=""
        role="presentation"
      />
      <TitleH1 className={"main-title"}>{lang[currentPage]}</TitleH1>
    </DesktopApplicationStyle>
  );
}

export const DesktopApplicationStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgb(204 204 204);

  .main-title {
    display: none;
    position: fixed;
    right: 45px;
    top: 30px;
    font-size: 26px;
    max-width: 500px;
  }

  .close {
    svg {
      width: 50px;
      height: 50px;
    }

    position: fixed;
    left: 10px;
    top: 10px;
    z-index: 5;
  }

  img {
    position: fixed;
    opacity: 0.4;
  }

  .image-5 {
    width: 195px;
    left: 109px;
    top: 80px;
  }

  .image-4 {
    width: 166px;
    right: -20px;
    bottom: 18px;
  }

  .image-3 {
    width: 166px;
    right: 60px;
    top: 170px;
  }

  .image-2 {
    width: 166px;
    left: -60px;
    bottom: 170px;
  }

  .image-1 {
    width: 166px;
    left: 60px;
    bottom: -25px;
  }

  .i-frame {
    width: 700px;
    height: ${(props) => props.height - 85}px;
    border: solid 1px #dadada;
    border-radius: 10px;
    background-color: white;
  }

  .outher-tablet {
    width: 720px;
    border: solid 1px #e6e6e6;
    border-radius: 10px;
    box-shadow: rgb(138 136 136) 6px 6px 6px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    z-index: 10;
  }

  .speaker-tablet {
    width: 30px;
    height: 3px;
    margin: 10px 0;
    border-radius: 5px;
    background-color: #dadada;
    border: solid 1px #c5c5c5;
  }

  .button-tablet {
    height: 15px;
    width: 35px;
    border-radius: 40px;
    border: 2px solid rgb(208, 208, 208);
    margin: 7px 0px;
    background-color: rgb(230, 230, 230);
  }
`;

export default DesktopApplication;
