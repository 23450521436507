export const ARTICLES = [
    {
      lang: "he",
      url: "sending-packages-with-bambizz",
      pageTitle: "משלוח מהיום להיום? משלוח מהיר? משלוח אקספרס?",
      pageSubTitle:
        "לא יודעים מה המשלוח שהכי מתאים לכם? במביז מציעה לכם את הפתרון המשולם. המשלוח הכי זול ואישי שיש! בנוסף אתם שותפים לתרומה אמיתית לחברה כי אנחנו חברתיים באמת. כל אחד יכול לשלוח ולהיות שליח.",
      pageImageUrl:
        "https://bambizz.com/images/signing-shipping-forms-for-large-box.jpg",
      pagePostedDescription: "",
      sections: [
        {
          title: "קצת סדר בבלגן. איזה משלוח אני צריך? משלוח מהיר מהיום להיום?",
          para: [
            "במביז הפתרון המתאים ביותר בשבילך למשלוח מהיר מהיום להיום.",
            "בבמביז אותו שליח אוסף ומוסר את החבילה. המשלוח שלך יגיע בצורה הבטוחה ביותר, ",
          ],
        },
        {
          title: "משלוח מיוחד? לא רוצים שהמשלוח שלכם ייהרס בדרך?",
          para: [
            "במביז הפתרון למשלוח מדלת לדלת באותו יום ובשמירה על המשלוח שלכם שלא ייהרס.",
          ],
        },
        {
          title: "לא לחוצים על המשלוח ורוצים לקבל משלוח במחיר הכי טוב בשוק?",
          para: [
            "במביז מתאימים לכם בול! השליחים החברתיים שלנו ישמחו להעביר לכם את המשלוח במחיר ללא תחרות.",
          ],
        },
        {
          title: "למה כדאי לשלוח באפליקציה?",
          para: [
            "באפליקציה אתם מקבילים שליטה מלאה על החבילה , עם מעקב צמוד של השליח ושל החבילה.",
          ],
        },
        {
          title: "סקירת שוק המשלוחים בישראל.",
          para: [
            "שוק המשלוחים בישראל הוא מהשווקים הפעילים במשק. במדינות רבות הדואר הממשלתי הוא שירות הדואר הפופולארי ביותר בשוק המשלוחים המקומי, בישראל חברת הדואר מספקת שירותי דואר למכתבים בעיקר, ",
            "בתוך שוק המשלוחים הכללי ישנו שוק המשלוחים המהירים ומשלוחי האקספרס או משלוחים מהיום להיום, ",
            "רוב חברות המשלוחים  בארץ לא מספקות משלוחי אקספרס או שירות משלוחים מהיום להיום, או שליח מדלת לדלת,  אלא עובדות בצורת ״מרכז הפצה״ כלומר אוספות ביום אחד ושולחות את כל החבילות למרכז הפצה אחד ולאחר מכן בימים הקרובים מפיצים את החבילות לפי האזורים שנבחרו.",
          ],
        },
        {
          title: "הייתרון במרכז הפצה למשלוחים הוא היכולת לשלוח לכל מקום בארץ.",
          para: [
            "הכיסוי של הארץ בשלמות מתאים מאוד לחברות גדולות ששולחות מסה של משלוחים רבים כל יום לכל הארץ וצריכות מחיר פיקס לכל המשלוחים, בדך כלל החברות גובות דמי מנוי או מינימום הזמנות בכדי לקבל את השירות של משלוח דרך מרכז הפצה במחיר זול,",
          ],
        },
        {
          title:
            "עקב האכילס המרכזי של מרכזי ההפצה הוא בעיקר למשלוחים לעסקים קטנים או  משלוחים ללקוחות פרטיים",
          para: [
            "היות והחברות לא יבואו לאסוף חבילה אחת, הם יבואו לאסוף רק כמות משמעותית של חבילות שבדרך כלל לעסק הקטן אין אפשרות לשלוח הרבה חבילות יחד,",
            "סיבה נוספת שמרכז ההפצה לא מתאים למשלוחים של עסקים קטנים או משלוחים לעסקי בוטיק הוא.",
          ],
        },
        {
          title:
            "עסק קטן שרוצה שחבילה תגיע באותו יום ליעד, או משלוח אקספרס, או משלוח רגיש שיכול להיהרס אם הוא יגיע למרכז הפצה, כגון משלוח עוגה או מארז מתנה רגיש, או סתם משלוח בהתאמה אישית ללקוח. לא יכול להשתמש בשירותים של חברות המשלוחים הארציים,",
          para: [],
        },
        {
          title:
            "דמי המנוי שהחברות דורשות ואו מינימום המשלוחים הם בלתי אפשריים לעסק קטן,",
          para: [
            "אז איך אני יכול בכל זאת לשלוח משלוח כעסק קטן בלי לשלם הון לחברת מוניות וכד'",
          ],
        },
        {
          title: "הפתרון הוא משלוחי אקספרס ומשלוח מדלת לדלת .",
          para: [],
        },
        {
          title: "הפתרון הוא במביז. רשת השליחים החברתית של ישראל !",
          para: [],
        },
      ],
      seoTitle: "משלוח מהיום להיום? משלוח מהיר? משלוח אקספרס?",
      seoDescription:
        "לא יודעים מה המשלוח שהכי מתאים לכם? במביז מציעה לכם את הפתרון המשולם. המשלוח הכי זול ואישי שיש! בנוסף אתם שותפים לתרומה אמיתית לחברה כי אנחנו חברתיים באמת. כל אחד יכול לשלוח ולהיות שליח.",
    },
    {
      lang: "he",
      url: "packages-on-the-way",
      pageTitle:
        "מהו משלוח חברתי על הדרך. והדרך הטובה ביותר למשלוח חבילה על הדרך.",
      pageSubTitle:
        "כשאתם רוצים להעביר חבילה ליעד מסוים אך לא צריכים להעביר במשלוח מהיר ולשלם את העלות  היקרה יותר עבור העברת החבילה במשלוח אקספרס,  או במקרה שדחיפות החבילה אינה גבוהה אתם תהיו מוכנים להמתין קצת בכדי לשלוח את החבילה הזו.",
      pageImageUrl: "https://bambizz.com/images/mealpro-dd1M0Ji9xHQ-unsplash.jpg",
      pagePostedDescription: "",
      sections: [
        {
          title:
            "משלוח חברתי זאת למעשה הדרך הטובה שכל הצדדים יהיו מרוצים ומרוויחים במשלוח חבילה שאינה דחופה למסירה.",
          para: [
            "כשאתם רוצים להעביר חבילה ליעד מסוים אך לא צריכים להעביר במשלוח מהיר ולשלם את העלות  היקרה יותר עבור העברת החבילה במשלוח אקספרס,  או במקרה שדחיפות החבילה אינה גבוהה אתם תהיו מוכנים להמתין קצת בכדי לשלוח את החבילה הזו.",
            "אז מי בעצם השליח שייקח את החבילה הזו.",
            "בצד השני שלכם ישנו שליח פרטי מקצועי ואמין , שנוסע באזור משלוח החבילה שלכם.  אנחנו מאתרים אותו ומתאימים לכם אותו כשליח לחבילה שלכם. לכן העלות למשלוח תהיה זולה יותר ממשלוח שבו השליח צריך לצאת במיוחד בשבילכם. ",
            "נשמע מעולה! איך שולחים חבילה חברתית על הדרך בבמביז. ",
            "*מורידים את אפליקציית במביז בחנות האפליקציות .",
            "*מעלים לאפליקציית במביז את המשלוח- ובוחרים באפשרות של ״משלוח חברתי משלוח על הדרך״",
            "*״ממלאים את שאר הפרטים לתאום עם השליח, והכי חשוב ממלאים את הערה לשליח.  (לדוגמא אם אתם שולחים מוצר שביר, או חבילה שדורשת רכב, או כל הערה לשליח כגון ״קומה 5 בדלת הכחולה",
            "לוחצים על ״פרסם חבילה״",
            "מעכשיו אתם יכולים לעזוב את האפליקציה, אין צורך לעלות שוב ושוב את החבילה. המערכת של במביז תפרסם את המשלוח שלכם לאלפי השליחים שלנו, ובמידה שימצא שליח מתאים לחבילה שלכם תקבלו הודעה על כך. ",
            "לאחר קבלת ההודעה תוכלו לשוחח עם השליח ולתאם אתו את פרטי המשלוח.",
            "כמה זמן לחכות עד שנמצא לכם שליח מתאים?",
            "זה תלוי בכם. אנחנו נמשיך לפרסם את החבילה עד לזמן המקסימלי שהזנתם לפיזור המשלוח. עד אז  החבילה יכולה להיות מאושרת על ידיד שליח על הדרך",
            "האם יש ביטוח לחבילות ששולחים בבמביז",
            "כן יש ביטוח של 200 ₪ באופן אוטומטי לכל החבילות ששולחים בבמביז",
            "האם זה מתאים לעסקים, במביז מספקים חשבונית בעבור המשלוח ?",
            "ברור שכן, החשבונית ממתינה לכם לאחר מספר שעות על שם הנירשם לאפליקציה, כמו כן רוב העסקים בארץ שולחים בטווח של 3-7 ימי עסקים כך שזה מתאים מאוד לעסקים ואף יוזיל את ההוצאות",
            "תורמים לקהילה",
            "כשאתם שולחים משלוח חברתי אתם תורמים באמת לקהילה, אתם חוסכים אלפי שעות עבודה לשליחים, אתם נותנים פרנסה לחברים שלכם, אתם חוסכים בשעות רבות של רכבים ועומסי תנועה ושריפת דלקים מיותרת.",
            "הצלחה!!",
          ],
        },
      ],
      seoTitle:
        "מהו משלוח חברתי על הדרך. והדרך הטובה ביותר למשלוח חבילה על הדרך.",
      seoDescription:
        "כשאתם רוצים להעביר חבילה ליעד מסוים אך לא צריכים להעביר במשלוח מהיר ולשלם את העלות  היקרה יותר עבור העברת החבילה במשלוח אקספרס,  או במקרה שדחיפות החבילה אינה גבוהה אתם תהיו מוכנים להמתין קצת בכדי לשלוח את החבילה הזו.",
    },
  ];