import React, { useContext } from "react";
import styled from "styled-components";
import { COLORS } from "../common/constants";
import { get } from "lodash";
import { navigateTo } from "../common/function";
import { LanguageContext } from "../language/lang";

export const Button = ({ title, children, ...props }) => {
  let ariaLabel;

  if (children && typeof children === "string") {
    ariaLabel = children;
  } else {
    ariaLabel = title;
  }

  if (!ariaLabel) {
    console.warn(`button is missing ariaLabel`);
  }

  return (
    <ButtonStyle title={title} {...props} ariaLabel={ariaLabel}>
      {children}
    </ButtonStyle>
  );
};

export const ButtonStyle = styled.button.attrs(({ ariaLabel }) => ({
  "aria-label": ariaLabel,
}))`
  display: block;
  padding: 17px;
  font-size: 18px;
  background-color: #fff;
  border: solid 3px #393b45;
  border-radius: 40px;
  cursor: pointer;
  color: #393b45;
  line-height: 14px;

  &:focus {
    border: solid 2px black;
  }

  &:disabled {
    background-color: ${COLORS.disabled};
    border: ${COLORS.disabled};
  }
`;

export const IconButton = ({ title, children, ...props }) => {
  let ariaLabel;

  if (children && typeof children === "string") {
    ariaLabel = children;
  } else {
    ariaLabel = title;
  }

  if (!ariaLabel) {
    console.warn(`button is missing ariaLabel`);
  }

  return (
    <IconButtonStyle title={title} {...props} ariaLabel={ariaLabel}>
      {children}
    </IconButtonStyle>
  );
};

export const IconButtonStyle = styled.button.attrs(({ ariaLabel }) => ({
  "aria-label": ariaLabel,
}))`
  display: flex;
  font-size: 18px;
  background: none;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  svg {
    width: 30px;
    height: 30px;
  }
`;

export const IconA = ({ title, children, href, ...props }) => {
  let ariaLabel;

  if (children && typeof children === "string") {
    ariaLabel = children;
  } else {
    ariaLabel = title;
  }

  if (!ariaLabel) {
    console.warn(`link with href '${href}' is missing ariaLabel`);
  }

  return (
    <IconAStyle title={title} href={href} {...props} ariaLabel={ariaLabel}>
      {children}
    </IconAStyle>
  );
};

export const IconAStyle = styled.a.attrs(({ ariaLabel }) => ({
  "aria-label": ariaLabel,
}))`
  display: flex;
  font-size: 18px;
  background: none;
  padding: 10px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  width: fit-content;
  cursor: pointer;

  svg {
    width: 30px;
    height: 30px;
  }
`;

export const A = ({ href, realHref, title, children, onClick, ...props }) => {
  let ariaLabel;

  if (children && typeof children === "string") {
    ariaLabel = children;
  } else {
    ariaLabel = title;
  }

  if (!ariaLabel) {
    console.warn(`link with href '${href}' is missing ariaLabel`);
  }

  return (
    <AStyle
      title={title}
      href={href}
      ariaLabel={ariaLabel}
      onClick={async (e) => {
        e.preventDefault();
        if (typeof onClick === "function") {
          await onClick(e);
        }
        let url = realHref || href || "";
        window.scrollTo(0, 0);
        if (url.indexOf("http") !== -1) {
          window.location.href = realHref || href;
        } else {
          navigateTo(url);
        }
      }}
      {...props}
    >
      {children}
    </AStyle>
  );
};

export const AStyle = styled.a.attrs(({ ariaLabel }) => ({
  "aria-label": ariaLabel,
}))`
  display: block;
  padding: 10px;
  font-size: 18px;
  background-color: #fff;
  border: solid 3px #393b45;
  color: #393b45;
  border-radius: 40px;
  cursor: pointer;
  text-decoration: none;
  width: fit-content;
`;

export const Input = ({ placeholder, error, errorMessage, ...props }) => {
  const lang = useContext(LanguageContext);

  return (
    <InputStyle
      aria-label={placeholder}
      aria-errormessage={
        error ? lang.defaultErrorMessage || errorMessage : null
      }
      aria-invalid={!!error}
      error={error}
      placeholder={placeholder}
      ariaLabel={placeholder}
      {...props}
    />
  );
};

export const InputStyle = styled.input.attrs(({ ariaLabel }) => ({
  "aria-label": ariaLabel,
}))`
  font-size: 17px;
  padding: 10px 14px;
  display: block;
  border: ${(props) =>
    props.error ? "solid 1px red" : "solid 1px " + COLORS.lightBorder};
  width: calc(100% - 28px);
  border-radius: 40px;

  &:focus {
    border: solid 1px black;
  }
`;

export const TextArea = ({ placeholder, error, errorMessage, ...props }) => {
  const lang = useContext(LanguageContext);

  return (
    <TextAreaStyle
      aria-label={placeholder}
      aria-errormessage={
        error ? lang.defaultErrorMessage || errorMessage : null
      }
      aria-invalid={!!error}
      error={error}
      placeholder={placeholder}
      ariaLabel={placeholder}
      {...props}
    />
  );
};

export const TextAreaStyle = styled.textarea.attrs(({ ariaLabel }) => ({
  "aria-label": ariaLabel,
}))`
  font-size: 17px;
  padding: 10px 14px;
  font-family: Arial, serif;
  display: block;
  border: ${(props) =>
    props.error ? "solid 1px red" : "solid 1px " + COLORS.lightBorder};
  width: calc(100% - 28px);
  border-radius: 20px;
  resize: none;
  height: 100px;

  &:focus {
    border: solid 1px black;
  }
`;

export const Title = styled.div`
  color: #393b45;
  font-size: 20px;
`;

export const TitleH1 = styled.h1`
  color: #393b45;
  font-size: 20px;
  font-weight: 500;
`;

export const TitleH2 = styled.h2`
  color: #393b45;
  font-size: 20px;
`;

export const TitleH3 = styled.h3`
  color: #393b45;
  font-size: 20px;
  font-weight: normal;
`;

export const Select = styled.select`
  font-size: 17px;
  padding: 10px 5px;
  display: block;
  border: solid 1px ${COLORS.lightBorder};
  width: 100%;
  border-radius: 40px;
  ${(props) =>
    get(props, "lang.id", "") === "HE"
      ? "padding-left: 28px;"
      : "padding-right: 28px;"}
  text-overflow: ellipsis;
  overflow: hidden;
  background: url(/images/arrow.svg) no-repeat
    ${(props) => (get(props, "lang.id", "") === "HE" ? "left" : "right")} white;
  height: 42px;
  -webkit-appearance: none;

  &:focus {
    border: solid 1px black;
  }
`;
