import React from "react";
import { LanguageContext } from "../../language/lang";
import { ARTICLES } from "./constants";
import styled from "styled-components";
import { IconA } from "../Components";
import { ICHome, ICLogo } from "../Icons";

export default function ArticlePage({ page }) {
  const lang = React.useContext(LanguageContext);
  const article = ARTICLES.find(({ url }) => url === page);
  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    let listener = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  return (
    <PageStyle>
      <div className="page-container">
        <header className="page-header">
          {width < 980 && <ICLogo className="main-logo" />}
          <nav className="nav-panel">
            <IconA href="/" title={lang["Home"]}>
              <ICHome fill="grey" className="home-icon" />
            </IconA>
            <span>&gt;</span>
            <IconA href={"/" + article.lang + "/articles/"}>
              {lang["articles"]}
            </IconA>
            <span>&gt;</span>
            <IconA>{article.pageTitle}</IconA>
          </nav>
          <hr />
          <section>
            <header>
              <h2>{article.pageTitle}</h2>
              <p>{article.pageSubTitle}</p>
              <p className="posted-time">{article.pagePostedDescription}</p>
              <hr />
              <img
                alt=""
                role="presentation"
                src={article.pageImageUrl}
                width={Math.min(width - 30, 600)}
                height="600"
                layout="responsive"
              ></img>
            </header>
          </section>
        </header>
        <article className="article-main">
          {article.sections.map((section) => (
            <section>
              <h3>{section.title}</h3>
              {section.para.map((p) => (
                <p>{p}</p>
              ))}
            </section>
          ))}
        </article>
      </div>
    </PageStyle>
  );
}

const PageStyle = styled.main`
  a {
    padding: 0;
  }

  .home-icon {
    height: 25px;
  }
`;
