import React, { useEffect, useContext, useState } from "react";
import styled from "styled-components";
import { IconButton, TitleH1 } from "./Components";
import { LanguageContext } from "../language/lang";
import { reportPageView } from "../common/function";
import { MdClose } from "react-icons/md";
import { navigateTo } from "../common/function";

function DeleteAccount() {
  const [mobile, setmobile] = useState(window.innerWidth < 980);
  const lang = useContext(LanguageContext);
  useEffect(() => {
    reportPageView("/delete-account");
  }, []);
  useEffect(() => {
    let listener = () => {
      setmobile(window.innerWidth < 980);
    };
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  const link = mobile
    ? "https://app.bambizz.com/settings"
    : `/${lang.id.toLowerCase()}/application/settings`;

  return (
    <DeleteAccountStyle>
      <div className="flex" style={{ alignItems: "center" }}>
        <IconButton className="close" title={lang.close}>
          <MdClose fill={"#393b45"} onClick={() => navigateTo("/")} />
        </IconButton>
        <TitleH1 theme="sender" className="main-title">
          {lang["delete-account"]}
        </TitleH1>
      </div>
      <div className="container">
        <div
          className="text"
          dangerouslySetInnerHTML={{
            __html: lang["delete-account-text"].replace("{href}", link),
          }}
        />
      </div>
    </DeleteAccountStyle>
  );
}

export const DeleteAccountStyle = styled.div`
  .container {
    padding: 5%;
  }

  .text {
    margin: 30px 0;
  }

  svg {
    fill: #393b45;
  }

  .logo {
    display: block;
    margin: auto;
  }

  .hidden {
    visibility: hidden;
  }

  .flex46 {
    flex: 0 0 46%;
  }

  textarea {
    height: 57px;
  }

  label {
    display: block;
    margin: 3px 2px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: 16px;
  }

  .main-title {
    text-align: center;
    font-size: 22px;
    margin: 18px 0;
  }

  .get-price {
    margin: auto;
    margin-top: 40px;
  }

  #svg10 {
    height: 99px;
    width: 100%;
  }

  @media only screen and (min-width: 980px) {
    background-color: white;
    .flex {
      justify-content: center;
    }

    .container {
      padding-top: 10px;
    }

    .close,
    .logo {
      display: none;
    }

    .main-title {
      font-size: 50px;
      margin-top: 50px;
    }

    .text {
      width: 800px;
      margin: auto;
      font-size: 20px;
    }
  }
`;

export default DeleteAccount;
