import React, { useContext } from "react";
import { setLang, LanguageContext, LANGUAGES } from "../language/lang";
import { Select, Title, IconA, A } from "./Components";
import { ICLogo } from "./Icons";
import styled from "styled-components";
import { COLORS } from "../common/constants";
import { getDynemicLink, LINK_TYPE, navigateTo } from "../common/function";

function MenuBarDesktop() {
  const lang = useContext(LanguageContext);
  return (
    <>
      <div
        style={{ height: "80px", backgroundColor: COLORS.backgroundColor }}
      />
      <MenuBarDesktopStyle>
        <div className="flex" role="menu">
          <IconA
            title={lang["Home"]}
            href={"/" + lang.id.toLowerCase() + "/"}
            onClick={(e) => handleLink(e, "/")}
            role="menuitem"
          >
            <ICLogo className="logo" notPresentation />
          </IconA>
          <IconA
            title={lang["Home"]}
            className="link-button"
            href={"/" + lang.id.toLowerCase() + "/"}
            onClick={(e) => handleLink(e, "/")}
            role="menuitem"
          >
            <Title className="black-title">{lang["Home"]}</Title>
          </IconA>
          <A
            title={lang["My Orders"]}
            className="link-button"
            realHref={getDynemicLink(LINK_TYPE.MY_ORDERS, lang.id)}
            href="https://bambizz.com/he/application/sender-my-orders"
            role="menuitem"
          >
            <Title className="black-title"> {lang["My Orders"]}</Title>
          </A>
          <IconA
            title={lang["About Us"]}
            className="link-button"
            href={"/" + lang.id.toLowerCase() + "/about-us"}
            onClick={(e) => handleLink(e, "/about-us")}
            role="menuitem"
          >
            <Title className="black-title"> {lang["About Us"]}</Title>
          </IconA>
          <IconA
            title={lang["Contact Us"]}
            className="link-button"
            href={"/" + lang.id.toLowerCase() + "/contact-us"}
            onClick={(e) => handleLink(e, "/contact-us")}
            role="menuitem"
          >
            <Title className="black-title">{lang["Contact Us"]}</Title>
          </IconA>
          <div className="links-container">
            {/*   <IconA className="link-button" title={'מבצעים'}
                        >
                            <Title className="black-title" style={{color: "#9938BE"}}>
                                מבצעים
                            </Title>
                        </IconA>
                        <div className="absolute-links-container">
                            <IconA
                                title={'20₪ הנחה למשלוח הראשון שלכם בבמביז'}
                                className="link-button"
                                href={
                                    "/" +
                                    lang.id.toLowerCase() +
                                    "/discount-jerusalem-and-tel-aviv-area"
                                }
                                onClick={(e) => handleLink(e, "/new-user-discount")}
                            >
                                <Title className="black-title" style={{color: "#9938BE"}}>
                                    20₪ הנחה למשלוח הראשון שלכם בבמביז
                                </Title>
                            </IconA>
                        </div>*/}
          </div>
          {lang.id === LANGUAGES.HE && (
            <div className="links-container">
              <IconA
                className="link-button"
                href={"/he/articles/"}
                title={"כתבות"}
                role="menuitem"
              >
                <Title className="black-title">כתבות</Title>
              </IconA>
              <div className="absolute-links-container">
                <IconA
                  title={"משלוח מהיום להיום?"}
                  className="link-button"
                  href={"/he/articles/sending-packages-with-bambizz"}
                >
                  <Title className="black-title">משלוח מהיום להיום?</Title>
                </IconA>
                <IconA
                  title={"משלוח חברתי"}
                  className="link-button"
                  href={"/he/articles/packages-on-the-way"}
                >
                  <Title className="black-title">משלוח חברתי</Title>
                </IconA>
              </div>
            </div>
          )}
          {lang.id === LANGUAGES.HE && (
            <IconA
              title={lang["FAQ"]}
              className="link-button"
              href={"/" + lang.id.toLowerCase() + "/faq"}
              onClick={(e) => handleLink(e, "/faq")}
              role="menuitem"
            >
              <Title className="black-title"> {lang["FAQ"]}</Title>
            </IconA>
          )}
          {lang.id === LANGUAGES.HE && (
            <IconA
              title={lang["businesses"]}
              className="link-button"
              href={"/" + lang.id.toLowerCase() + "/businesses"}
              onClick={(e) => handleLink(e, "/businesses")}
              role="menuitem"
            >
              <Title className="black-title"> {lang["businesses"]}</Title>
            </IconA>
          )}
        </div>
        <Select
          className="select-lang"
          value={lang.id}
          onChange={(e) => setLang(e.target.value)}
          aria-label={lang["selectLanguage"]}
        >
          <option value="EN">{lang["English"]}</option>
          <option value="HE">{lang["Hebrew"]}</option>
        </Select>
      </MenuBarDesktopStyle>
    </>
  );

  function handleLink(e, path) {
    e.preventDefault();
    navigateTo(path);
  }
}

const MenuBarDesktopStyle = styled.nav`
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 3px #e3e3e3;
  position: fixed;
  width: calc(100% - 20px);
  top: 0;
  left: 0;
  height: 70px;
  background-color: white;
  color: #393b45;
  padding: 10px;
  padding-bottom: 0;
  z-index: 5;

  .absolute-links-container {
    display: none;

    &:hover {
      position: absolute;
      background: white;
      border-radius: 5px;
      box-shadow: 2px 3px 3px #d0d0d0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      display: block;
    }
  }

  .links-container {
    .link-button {
      margin-bottom: 10px;
    }

    &:hover {
      .absolute-links-container {
        position: absolute;
        background: white;
        border-radius: 5px;
        box-shadow: 2px 3px 3px #d0d0d0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        display: block;
      }
    }
  }

  .link-button {
    padding: 17px;
    background: none;
    border: none;
  }

  .black-title {
    color: #393b45;
  }

  .select-lang {
    width: 120px;
    background-color: #00000000;
    border: none;
    margin: 9px;
    font-size: 20px;
  }

  .sender {
    color: ${COLORS.mainColorSender};
  }

  .courier {
    color: ${COLORS.mainColorCourier};
  }

  svg {
    margin: auto;
    display: block;
    width: 100px;
    height: 54px;
    padding: 5px;
    transform: translate(0px, -13px);
  }

  button {
    min-width: 90px;
  }

  .label {
    font-size: 14px;
  }

  .slide-up {
    animation-name: slide-up;
    animation-duration: 1s;
    ${() => `box-shadow: 0px 2px 7px #5f5f5f;`}
  }

  .slide-down {
    animation-name: slide-down;
    animation-duration: 1s;
    ${() => `box-shadow: 0px 2px 7px #5f5f5f;`}
    ${() => `transform: translate(0px,${window.screen.height}px);`}
  }

  @keyframes slide-up {
    from {
      ${() => `transform: translate(0px,${window.screen.height}px);`}
    }
    to {
      transform: translate(0, 0);
    }
  }
  @keyframes slide-down {
    to {
      ${() => `transform: translate(0px,${window.screen.height}px);`}
    }
    from {
      transform: translate(0, 0);
    }
  }
`;

export default MenuBarDesktop;
