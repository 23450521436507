import React, { useEffect, useContext, useState } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import {
  Button,
  Select,
  IconButton,
  Input,
  TextArea,
  TitleH1,
  TitleH2,
} from "./Components";
import { LanguageContext, LANGUAGES } from "../language/lang";
import { ICLogo } from "./Icons";
import { sendContactUs } from "../redux/actions";
import { get } from "lodash";
import { reportPageView, showMessage } from "../common/function";
import { MdMenu } from "react-icons/md";
import SideMenu from "./SideMenu";
import { setLang } from "../language/lang";
import { CONSTANTS } from "../common/constants";
import validator from "validator";
import { parsePhoneNumberFromString } from "libphonenumber-js";

function HomePageBusinesses({ onSend }) {
  const lang = useContext(LanguageContext);
  const dispatch = useDispatch();
  const [sideBar, setsideBar] = useState(false);
  const [fields, setfields] = useState({
    name: "",
    phone: "",
    email: "",
    description: "",
    numberOfOrders: "",
    area: "",
  });
  useEffect(() => {
    if (fields.name !== "") {
      let element = document.getElementById("hide-recaptcha");
      element.innerText = "";
    } else {
      let element = document.getElementById("hide-recaptcha");
      element.innerText = ".grecaptcha-badge{visibility: hidden;}";
    }
  }, [fields]);
  const [error, seterror] = useState({});

  useEffect(() => {
    reportPageView("/");
    return () => {
      let element = document.getElementById("hide-recaptcha");
      element.innerText = ".grecaptcha-badge{visibility: hidden;}";
    };
  }, []);
  return (
    <HomePageBusinessesStyle scale={lang.id === LANGUAGES.HE ? -1 : 1}>
      <div className="flex space-between">
        {sideBar && <SideMenu onClose={() => setsideBar(false)} />}
        <IconButton
          title={lang["menu"]}
          className="menu-button"
          onClick={() => setsideBar(true)}
        >
          <MdMenu />
        </IconButton>
        <Select
          className="select-lang"
          value={lang.id}
          onChange={(e) => setLang(e.target.value)}
          aria-label={lang["selectLanguage"]}
        >
          <option value="EN">{lang["English"]}</option>
          <option value="HE">{lang["Hebrew"]}</option>
        </Select>
      </div>
      <div
        className={
          "flex space-between " +
          (lang.id === LANGUAGES.HE ? "logo-container-he" : "logo-container-en")
        }
      >
        <img
          alt={lang["image-users"]}
          title={lang["image-users"]}
          className="image-1"
          src="/images/desktop-1.png"
        />
        <ICLogo className="logo" />
        <div style={{ width: 100 }} />
      </div>
      <TitleH1 className="title-1">
        {`הפצת משלוחים לעסקים בכל הארץ בשירות מעולה ומחיר משתלם`}
      </TitleH1>
      <TitleH2 className="title-2">
        {`שולחים מספר חבילות יחד? במביז הפצה בדיוק בשבילכם, הפצה מהירה, ובמחיר משתלם. השאירו פרטים ונשמח לחזור אליכם`}
      </TitleH2>
      <form className="contact-us-form">
        <Input
          className="name"
          placeholder={`שם חברה`}
          error={error.name}
          value={fields.name}
          onChange={(e) => setField("name", e.target.value)}
        />
        <Input
          className="phone"
          placeholder={`טלפון חברה`}
          error={error.phone}
          phone
          type="tel"
          value={fields.phone}
          onChange={(e) => setField("phone", e.target.value)}
        />
        <Input
          className="email"
          placeholder={lang["Email"]}
          error={error.email}
          type="email"
          value={fields.email}
          onChange={(e) => setField("email", e.target.value)}
        />
        <Input
          className="email"
          placeholder={`מספר משלוחים חודשי בממוצע`}
          error={error.numberOfOrders}
          type="number"
          value={fields.numberOfOrders}
          onChange={(e) => setField("numberOfOrders", e.target.value)}
        />
        <Input
          className="email"
          placeholder={`אזור בארץ`}
          error={error.area}
          type="address"
          value={fields.area}
          onChange={(e) => setField("area", e.target.value)}
        />
        <TextArea
          className="message"
          placeholder={`הערה`}
          error={error.description}
          value={fields.description}
          onChange={(e) => setField("description", e.target.value)}
        />
        <Button className="send" type="button" onClick={send}>
          {lang["Send"]}
        </Button>
      </form>
    </HomePageBusinessesStyle>
  );

  function send() {
    let err = {};
    Object.entries(fields || {}).forEach((ent) => {
      if (!ent[1]) {
        err[ent[0]] = true;
      }
      if (ent[0] === "email" && !validator.isEmail(ent[1])) {
        err[ent[0]] = true;
      }
      if (ent[0] === "name" && ent[1].split(" ").join("").length < 4) {
        err[ent[0]] = true;
      }
      if (ent[0] === "numberOfOrders") {
        err[ent[0]] = !(parseInt(ent[1], 10) > 0);
      }
      if (ent[0] === "phone") {
        let phoneCheck = parsePhoneNumberFromString(ent[1], "IL");
        if (!phoneCheck) {
          err[ent[0]] = true;
        } else {
          err[ent[0]] = !parsePhoneNumberFromString(ent[1], "IL").isValid();
        }
      }
    });
    seterror(err);
    if (Object.values(err).some((val) => val)) {
      showMessage(dispatch, lang["error"], lang["enter-valid-fields"]);
      return;
    }
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(CONSTANTS.captcha, { action: "contactUs" })
        .then((token) => {
          fields.description = `<br/>
          DESCRIPTION: "${fields.description}" <br/>
          NUMBER OF ORDERS PER MONTH: "${fields.numberOfOrders}" <br/>
          AREA: "${fields.area}" <br/>`;
          onSend(fields, token);
          setfields({
            name: "",
            phone: "",
            email: "",
            numberOfOrders: "",
            description: "",
            area: "",
          });
        });
    });
  }

  function setField(name, val) {
    setfields({ ...fields, [name]: val });
  }
}

export const HomePageBusinessesStyle = styled.div`
  padding: 15px;

  .select-lang {
    width: 100px;
    background-color: #00000000;
    border: none;
  }

  .name {
    line-height: 43px;
  }

  .logo {
    width: 160px;
    align-self: center;
    height: 160px;
  }

  .menu-button {
    padding: 0 11px;
  }

  .sub-title {
    margin: 32px 50px;
    font-weight: 500;
    font-size: 20px;
  }

  padding: 15px 0;
  background-color: white;
  overflow-x: hidden;

  .login {
    padding: 12px 12px;
  }

  .image-1 {
    height: 100px;
    width: 100px;
    transform: translate(${(props) => props.scale * -22}px, -4px);
  }

  .social-container {
    justify-content: center;
    display: flex;

    .facebook {
      margin: 0 20px;

      svg {
        width: 30px;
        height: 30px;
        fill: #888888;
      }
    }

    .instagram {
      margin: 0 20px;

      svg {
        width: 33px;
        height: 33px;
        fill: #888888;
        transform: translate(0px, -1px);
      }
    }
  }

  .all-rights {
    text-align: center;
    line-height: 24px;
  }

  .store-buttons-con {
    width: 500px;
    margin: auto;
  }

  .terms-container {
    width: fit-content;
    margin: auto;
    text-align: center;
    margin-bottom: 50px;
    line-height: 43px;
  }

  .contact-us-form {
    margin: 40px;
  }

  .name,
  .phone {
    height: 41px;
    flex: 0 0 43%;
    margin: 20px 0;
  }

  .email {
    height: 41px;
    flex: 0 0 43%;
    margin: 20px 0;
  }

  .send {
    margin: auto;
    margin-top: 40px;
    margin-bottom: 100px;
    font-size: 18px;
    width: 100%;
    text-align: center;
    line-height: 15px;
  }

  .card {
    width: 300px;
    margin: auto;
    margin-bottom: 50px;
  }

  .icon {
    display: block;
    margin: auto;
    min-height: 62px;
  }

  .title {
    font-size: 21px;
    font-weight: bold;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 17px;
  }

  .text {
    text-align: center;
  }

  .table-container {
    max-width: 1000px;
    margin: auto;
    margin-top: 60px;
  }

  .title-1 {
    font-size: 24px;
    width: 300px;
    color: #393b45;
    font-weight: bold;
    text-align: center;
    margin: auto;
  }

  .title-2 {
    text-align: center;
    font-size: 16px;
    color: #393b45;
    width: 208px;
    margin: auto;
    margin-top: 25px;
  }

  .title-3 {
    font-size: 24px;
    color: #393b45;
    text-align: center;
    padding: 40px;
    padding-bottom: 25px;
    font-weight: bold;
  }

  .title-4 {
    font-size: 16px;
    color: #393b45;
    width: 300px;
    margin: auto;
    text-align: center;
  }

  .image-phone {
    height: 600px;
    display: block;
    margin: auto;
    margin-top: 25px;
  }

  .image-3 {
    height: 238px;
    margin: 0 35px;
    transform: translate(0px, 100px);
  }

  .image-5 {
    height: 288px;
    align-self: flex-end;
    transform: translate(0px, -150px);
  }

  .images-3 {
    margin: 63px 0px;
  }

  .section-2 {
    margin: 40px 0;
  }

  .section-3 {
    background-color: #d8d9de36;
    padding-bottom: 30px;
    margin-top: 200px;
  }

  .image-2 {
    height: 173px;
    width: 173px;
    transform: translate(${(props) => props.scale * -56}px, 10px);
    position: absolute;
  }

  .store-button {
    &.link {
      border: none;
      padding: 10px;
    }

    &.send-now {
      background-color: black;
      width: 188px;
      border-radius: 10px;
      border: solid 2px grey;
      margin-bottom: 10px;
    }

    margin: auto;
    margin-top: 50px;
    justify-content: space-between;
    background: none;
    padding: 0;
    width: unset;
    display: flex;
    justify-content: center;
    margin-top: 0;
    transform: translate(0px, -38px);

    svg {
      width: 210px;
      height: 66px;
    }
  }

  .image-4 {
    height: 108px;
    width: 108px;
    position: absolute;
    transform: translate(-35px, -76px);
    left: 0;
  }

  .select-lang {
    width: 100px;
    background-color: #00000000;
    border: none;
  }

  .name {
    line-height: 43px;
  }

  .menu-button {
    padding: 0 11px;
  }

  .sub-title {
    margin: 32px 50px;
    font-weight: 500;
    font-size: 20px;
  }

  .main-button {
    margin-bottom: 26px;
    padding: 13px;
    font-size: 22px;
    width: 260px;
    text-align: center;

    &.volunteer {
      font-size: 17px;
    }
  }

  .main-title {
    font-size: 29px;
  }
`;

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onSend: (fields, token) =>
    dispatch(
      sendContactUs({
        captcha: token,
        name: get(fields, "name", ""),
        phone: get(fields, "phone", ""),
        email: get(fields, "email", ""),
        type: get(fields, "type", ""),
        description: get(fields, "description", ""),
        emailTitle: "Bambizz Businesses: Contact Us",
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePageBusinesses);
