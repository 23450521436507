import { AiFillInstagram } from "react-icons/ai";
import { FaFacebook } from "react-icons/fa";
import { LanguageContext } from "../language/lang";
import React, { useContext } from "react";
import styled from "styled-components";
import { IconA } from "./Components";

export const FooterDesktop = () => {
  const lang = useContext(LanguageContext);

  return (
    <FooterStyle>
      <div className="social-container display-flex">
        <IconA
          className="facebook"
          aria-label="Facebook"
          title="Facebook"
          href="https://www.facebook.com/Bambizzcom-112424957081437/"
          target="_blank"
        >
          <FaFacebook />
        </IconA>
        <IconA
          className="instagram"
          aria-label="Instagram"
          title="Instagram"
          href="https://www.instagram.com/bambizz_com/"
          target="_blank"
        >
          <AiFillInstagram />
        </IconA>
      </div>
      <div className="terms-container">
        <IconA
          target="_blank"
          title={lang["Terms and Conditions Long"]}
          href="/en/terms-and-conditions"
        >
          {lang["Terms and Conditions Long"]}
        </IconA>
        <div className="all-rights">
          <span>{lang["© 2020 Bambizz. All rights reserved."]}</span>
        </div>
      </div>
    </FooterStyle>
  );
};

const FooterStyle = styled.footer`
  .social-container {
    justify-content: center;
    display: flex;

    .facebook {
      margin: 0 20px;

      svg {
        width: 30px;
        height: 30px;
        fill: #888888;
      }
    }

    .instagram {
      margin: 0 20px;

      svg {
        width: 33px;
        height: 33px;
        fill: #888888;
        transform: translate(0px, -1px);
      }
    }
  }

  .terms-container {
    width: fit-content;
    margin: auto;
    text-align: center;
    margin-bottom: 50px;
    line-height: 43px;

    a {
      color: #393b45;
      margin: auto;
    }
  }

  .all-rights {
    text-align: center;
    line-height: 24px;
  }
`;
