import he from './he.json';
import en from './en.json';
import React from 'react';
import { get } from 'lodash'
import { navigate } from 'hookrouter';
import { reportError } from '../common/function';


export const LANGUAGES = {
    HE: 'HE',
    EN: 'EN',
}

const setHTMLLang = (lang) => {
    try {
        document.getElementById('html-tag').lang = lang.toLowerCase();
    } catch (err) {
        reportError(err);
    }
}

export const LanguageContext = React.createContext({});
export const setLang = (str) => {
    switch (str) {
        case LANGUAGES.EN:
            localStorage.setItem('language', LANGUAGES.EN);
            navigate('/en' + get(window, 'location.pathname', '').substr(3));
            setHTMLLang(LANGUAGES.EN);
            break;
        case LANGUAGES.HE:
            localStorage.setItem('language', LANGUAGES.HE);
            navigate('/he' + get(window, 'location.pathname', '').substr(3));
            setHTMLLang(LANGUAGES.HE);
            break;
    }
}
const getLang = () => {
    let saved = localStorage.getItem('language');
    switch (saved) {
        case LANGUAGES.HE:
            localStorage.setItem('language', LANGUAGES.HE);
            setHTMLLang(LANGUAGES.HE);
            return he;
        case LANGUAGES.EN:
            localStorage.setItem('language', LANGUAGES.EN);
            setHTMLLang(LANGUAGES.EN);
            return en;
    }
    let str = get(window, 'location.pathname', '').substr(1, 2).toUpperCase();
    switch (str) {
        case LANGUAGES.HE:
            setHTMLLang(LANGUAGES.HE);
            return he;
        case LANGUAGES.EN:
            setHTMLLang(LANGUAGES.EN);
            return en;
    }
    let def = (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        navigator.userLanguage;
    if (def.toUpperCase().indexOf(LANGUAGES.HE) !== -1) {
        setHTMLLang(LANGUAGES.HE);
        return he;
    }
    if (def.toUpperCase().indexOf(LANGUAGES.EN) !== -1) {
        setHTMLLang(LANGUAGES.EN);
        return en;
    }
    return en;
}

export default getLang;

