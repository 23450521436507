import initialState from "./initialState";
import { ACTIONS } from "./actions";
import { reportError } from "../common/function";

const reduce = (state = initialState, action) => {
  try {
    switch (action.type) {
      case ACTIONS.CLOSE_MODAL:
        return { ...state, modal: false };
      case ACTIONS.HIDE_LOADER:
        return { ...state, loader: false };
      case ACTIONS.SHOW_LOADER:
        return { ...state, loader: true };
      case ACTIONS.OPEN_MODAL:
        return { ...state, modal: action.payload };
      default:
        return state;
    }
  } catch (err) {
    reportError(err);
    return initialState;
  }
};

export default reduce;
