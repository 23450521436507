import React, { useContext, useEffect, useRef, useState } from "react";
import { LANGUAGES, LanguageContext } from "../language/lang";
import { MdClose } from "react-icons/md";
import { IconButton, A } from "./Components";
import styled from "styled-components";
import { COLORS } from "../common/constants";
import { getDynemicLink, LINK_TYPE } from "../common/function";
import { ICMyOrders } from "./Icons";
import { FiHelpCircle, FiFileText } from "react-icons/fi";
import { navigateTo } from "../common/function";
import FocusTrap from "focus-trap-react";
import { useOnEsc } from "../hooks/useOnEsc";

function SideMenu({ className, onClose }) {
  const lang = useContext(LanguageContext);
  const [slideStyle, setslideStyle] = useState("slide-in");
  const menu = useRef();

  useOnEsc({
    onEscPressed: onClose,
  });

  useEffect(() => {
    console.log(menu.current);
    setTimeout(() => menu.current.focus(), 100);
  }, []);

  return (
    <FocusTrap>
      <div
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "#000000aa",
          zIndex: "5",
        }}
      >
        <SideMenuStyle
          className={slideStyle + " " + (className || "")}
          lang={lang.id}
        >
          <div
            className="flex"
            style={{ justifyContent: "flex-end" }}
            ref={menu}
          >
            <IconButton onClick={() => close()} title={lang.close}>
              <MdClose />
            </IconButton>
          </div>
          <div className="container" role="menu">
            <hr />
            <div className="flex space-between">
              <A
                title={lang["Contact Us"]}
                role="menuitem"
                href={"/contact-us"}
                onClick={(e) => {
                  e.preventDefault();
                  navigateTo("/contact-us");
                }}
                style={{
                  background: "none",
                  border: "none",
                  minWidth: "85px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ margin: "auto" }}>
                  <FiFileText />
                  <div className="label">{lang["Contact Us"]}</div>
                </div>
              </A>
              <A
                role="menuitem"
                title={lang["About Us"]}
                href={"/about-us"}
                onClick={(e) => {
                  e.preventDefault();
                  navigateTo("/about-us");
                }}
                style={{
                  background: "none",
                  border: "none",
                  minWidth: "85px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ margin: "auto" }}>
                  <FiHelpCircle />
                  <div className="label">{lang["About Us"]}</div>
                </div>
              </A>
            </div>
            <div className="flex space-between">
              <A
                role="menuitem"
                title={lang["Terms and Conditions"]}
                href={"/terms-and-conditions"}
                onClick={(e) => {
                  e.preventDefault();
                  navigateTo("/terms-and-conditions");
                }}
                style={{
                  background: "none",
                  border: "none",
                  minWidth: "85px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ margin: "auto" }}>
                  <FiFileText />
                  <div className="label">{lang["Terms and Conditions"]}</div>
                </div>
              </A>
              <A
                role="menuitem"
                title={lang["My Orders"]}
                realHref={getDynemicLink(LINK_TYPE.MY_ORDERS, lang.id)}
                href="https://app.bambizz.com/sender-my-orders"
                target="_blank"
                style={{
                  background: "none",
                  border: "none",
                  minWidth: "85px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ margin: "auto" }}>
                  <ICMyOrders />
                  <div className="label" style={{ fontSize: "13px" }}>
                    {lang["My Orders"]}
                  </div>
                </div>
              </A>
            </div>
            {lang.id === LANGUAGES.HE && (
              <div className="flex space-between">
                <A
                  role="menuitem"
                  title={"שאלות נפוצות"}
                  href={"/faq"}
                  onClick={(e) => {
                    e.preventDefault();
                    navigateTo("/faq");
                  }}
                  style={{
                    background: "none",
                    border: "none",
                    minWidth: "85px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ margin: "auto" }}>
                    <FiHelpCircle />
                    <div className="label">{"שאלות נפוצות"}</div>
                  </div>
                </A>
              </div>
            )}
          </div>
        </SideMenuStyle>
      </div>
    </FocusTrap>
  );

  function close() {
    setslideStyle("slide-out");
    setTimeout(() => {
      return typeof onClose === "function" ? onClose() : null;
    }, 300);
  }
}

export const SideMenuStyle = styled.nav`
  position: fixed;
  z-index: 2;
  background-color: white;
  width: ${250}px;
  height: 100%;
  top: 0;
  ${(props) => (props.lang !== "HE" ? "left:0;" : "right:0;")}
  background-color: ${COLORS.backgroundColor};

  svg {
    margin: auto;
    display: block;
    width: 24px;
    height: 24px;
    padding: 5px;
  }

  .container {
    padding: 0 20px;
  }

  button {
    min-width: 90px;
  }

  .label {
    font-size: 13px;
    color: #505050;
  }

  .name {
    padding: 2px 22px;
  }

  .sender {
    background-color: ${COLORS.senderBackground};
  }

  .courier {
    background-color: ${COLORS.courierBackground};
  }

  .date {
    font-size: 12px;
    line-height: 21px;
  }

  .text {
    font-size: 16px;
    margin: 8px 0;
  }

  @media only screen and (min-width: 980px) {
    border-radius: 12px;
  }

  .main-title {
    padding: 19px 0px;
  }

  .select-lang {
    margin: 15px 14px;
  }

  svg {
    path,
    polyline,
    line,
    circle {
      color: #9b9aa1;
    }
  }

  .scroll-area {
    overflow: scroll;
    height: calc(100% - 81px);
  }

  &.slide-in {
    animation-name: slide-in;
    animation-duration: 0.3s;
    ${(props) => `box-shadow: 0px 2px 7px #5f5f5f;`}
  }

  &.slide-out {
    animation-name: slide-out;
    animation-duration: 0.3s;
    ${(props) => `box-shadow: 2px 2px 7px #5f5f5f;`}
    ${(props) =>
      `transform: translate(${props.lang === "HE" ? 250 : -250}px,0px);`}
  }

  @keyframes slide-in {
    from {
      ${(props) =>
        `transform: translate(${props.lang === "HE" ? 250 : -250}px,0px);`}
    }
    to {
      transform: translate(0, 0);
    }
  }
  @keyframes slide-out {
    to {
      ${(props) =>
        `transform: translate(${props.lang === "HE" ? 250 : -250}px,0px);`}
    }
    from {
      transform: translate(0, 0);
    }
  }
`;

export default SideMenu;
