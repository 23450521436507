import { useEffect } from "react";

export const useOnEsc = ({ onEscPressed }) => {
  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 27) {
        onEscPressed?.();
      }
    }

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);
};
