import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../common/constants';

function Loader() {
    let color = COLORS.mainColorCourier;
    if (window.location.pathname.indexOf('sender') !== -1) {
        color = COLORS.mainColorSender;
    }
    return <LoaderStyle>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{ margin: 'auto', background: '0 0', display: 'block', shapeRendering: 'auto' }}
            width="200px"
            height="200px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid">
            <circle
                cx="50"
                cy="50"
                r="32"
                strokeWidth="8"
                stroke={color}
                strokeDasharray="50.26548245743669 50.26548245743669"
                fill="none"
                strokeLinecap="round"
                transform="rotate(359.477 49.9995 49.9995)">
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    repeatCount="indefinite"
                    dur="0.7633587786259541s"
                    keyTimes="0;1"
                    values="0 50 50;360 50 50" />
            </circle>
        </svg>
    </LoaderStyle >;
}

export const LoaderStyle = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #0000007a;
    z-index:5;
    svg{
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }
`;

export default Loader;