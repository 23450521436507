import React, { useEffect, useContext, useState } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import {
  Button,
  A,
  Input,
  TextArea,
  TitleH1,
  TitleH2,
  TitleH3,
} from "./Components";
import { LanguageContext, LANGUAGES } from "../language/lang";
import {
  ICLocations,
  ICCalculator,
  ICConversation,
  ICBoxPack,
  ICSavingsPig,
  ICPhoneConnection,
  ICGooglePlayHE,
  ICGooglePlayEN,
  ICAppleHE,
  ICAppleEN,
} from "./Icons";
import { get } from "lodash";
import {
  reportPageView,
  showMessage,
  LINK_TYPE,
  getDynemicLink,
  reportAppStoreClick,
  reportGooglePlayClick,
  reportSendNowClick,
} from "../common/function";
import { CONSTANTS } from "../common/constants";
import { sendContactUs } from "../redux/actions";
import validator from "validator";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import FAQComponent from "./FAQComponent";

function HomePageDesktop({ onSend }) {
  const lang = useContext(LanguageContext);
  const dispatch = useDispatch();
  const [fields, setfields] = useState({
    name: "",
    phone: "",
    email: "",
    description: "",
  });
  useEffect(() => {
    if (fields.name !== "") {
      let element = document.getElementById("hide-recaptcha");
      element.innerText = "";
    } else {
      let element = document.getElementById("hide-recaptcha");
      element.innerText = ".grecaptcha-badge{visibility: hidden;}";
    }
  }, [fields]);
  const [error, seterror] = useState({});
  useEffect(() => {
    reportPageView("/");
    return () => {
      let element = document.getElementById("hide-recaptcha");
      element.innerText = ".grecaptcha-badge{visibility: hidden;}";
    };
  }, []);
  return (
    <HomePageDesktopStyle lang={lang.id}>
      <img
        alt={""}
        role="presentation"
        title={lang["image-users"]}
        className="image-1"
        src="/images/desktop-1.png"
      />
      <TitleH1 className="title-1">
        {lang["Bambizz, Crowdsourced Delivery at its Best! Join today."]}
      </TitleH1>
      <div className="flex space-between section-2">
        <img
          alt={""}
          role="presentation"
          title={lang["image-users"]}
          className="image-2"
          src="/images/desktop-2.png"
        />
        <div className="welcome">
          <TitleH2 className="title-2">
            {
              lang[
                "Welcome to Bambizz, Crowdsourced Delivery at its Best! Send or deliver packages for great rates."
              ]
            }
          </TitleH2>
          <div className="flex space-between store-buttons-con">
            <A
              title={lang["google-play"]}
              onClick={reportGooglePlayClick}
              className="store-button link"
              target="_blank"
              realHref={getDynemicLink(LINK_TYPE.GOOGLE_PLAY, lang.id)}
              href="https://play.google.com/store/apps/details?id=com.bambizz"
            >
              {lang.id === LANGUAGES.HE ? (
                <ICGooglePlayHE className="store-icon" />
              ) : (
                <ICGooglePlayEN className="store-icon" />
              )}
            </A>
            <A
              title={lang["app-store"]}
              onClick={reportAppStoreClick}
              className="store-button link"
              target="_blank"
              realHref={getDynemicLink(LINK_TYPE.APP_STORE, lang.id)}
              href="https://apps.apple.com/app/id1509656952"
            >
              {lang.id === LANGUAGES.HE ? (
                <ICAppleHE className="store-icon" />
              ) : (
                <ICAppleEN className="store-icon" />
              )}
            </A>
          </div>
          <A
            title={lang["send-now"]}
            onClick={reportSendNowClick}
            className="send-now-button"
            realHref={getDynemicLink(LINK_TYPE.SEND_NOW, lang.id)}
            href={`https://bambizz.com/${lang.id.toLowerCase()}/application/sender-input-order-1`}
          >
            {lang["send-now"]}
          </A>
        </div>
        <img
          alt={""}
          role="presentation"
          title={lang["image-users"]}
          className="image-4"
          src="/images/desktop-4.png"
        />
      </div>
      {/*  <A
        className=""
        style={{
          background: COLORS.mainColorSender,
          borderColor: COLORS.mainColorSender,
          margin: "auto",
          marginBottom: "20px",
          borderRadius: "10px",
        }}
        realHref="/discount-jerusalem-and-tel-aviv-area"
        href="/he/discount-jerusalem-and-tel-aviv-area"
      >
        <Title
          style={{
            color: "white",
            fontSize: "30px",
            padding: "10px",
          }}
        >
          מבצע ירושלים והמרכז ב29.90₪
        </Title>
        </A>*/}

      <div className="section-3">
        <TitleH2 className="title-3">
          {lang["Bambizz is Easy and Simple to Use!"]}
        </TitleH2>
        <TitleH3 className="title-4" style={{ textAlign: "start" }}>
          {
            lang[
              'Sending a Package? Click "Send Package Now" and we\'ll post your package to our couriers.'
            ]
          }
        </TitleH3>
        <TitleH3 className="title-4" style={{ textAlign: "start" }}>
          {
            lang[
              'New Courier? Click "Start Delivering Packages" and begin earning delivery fees today!'
            ]
          }
        </TitleH3>
        <div className="flex space-around images-3">
          <img
            alt={""}
            role="presentation"
            title={lang["image-users"]}
            className="image-3"
            src="/images/desktop-3.png"
          />
          <img
            alt={lang["image-phone"]}
            title={lang["image-phone"]}
            className="image-phone"
            src={
              lang.id === LANGUAGES.EN
                ? "/images/screen_shot_en.png"
                : "/images/screen_shot_he.png"
            }
          />
          <img
            alt={""}
            role="presentation"
            title={lang["image-users"]}
            className="image-5"
            src="/images/desktop-5.png"
          />
        </div>
      </div>
      <div className="section-4">
        <TitleH2 className="title-3">{lang["Bambizz Features"]}</TitleH2>
        <TitleH3 className="title-4">
          {
            lang[
              "Join us in creating the largest ever Global Crowdshipping Community! Shared resources means everyone wins. Bambizz is here to help make it happen!"
            ]
          }
        </TitleH3>
        <div className="table-container">
          <div className="row">
            <div className="card">
              <ICLocations className="icon" />
              <TitleH3 className="title" theme="sender">
                {lang["Send a Package Anywhere in the World!"]}
              </TitleH3>
              <div className="text">
                {
                  lang[
                    "Bambizz allows you to ship and deliver packages anywhere in the world, thanks to our global network of couriers. This means that you don't have to be tied to a specific shipping company. We're here to build a global community with you!"
                  ]
                }
              </div>
            </div>
            <div className="card">
              <ICCalculator className="icon" />
              <TitleH3 className="title" theme="sender">
                {lang["The Price is Right!"]}
              </TitleH3>
              <div className="text">
                {
                  lang[
                    "All the prices on Bambizz are completely transparent. You choose the price that's right for you, without any surprise charges. Using existing community resources means an opportunity for great delivery rates!"
                  ]
                }
              </div>
            </div>
            <div className="card">
              <ICConversation className="icon" />
              <TitleH3 className="title" theme="sender">
                {lang["Direct Communication!"]}
              </TitleH3>
              <div className="text">
                {
                  lang[
                    "Bambizz enables direct communication between our senders and couriers. On-site ratings help you make your courier or sender choice, and you choose the pickup time and price that work best for you."
                  ]
                }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="card">
              <ICBoxPack className="icon" />
              <TitleH3 className="title" theme="sender">
                {lang["Send Any Package!"]}
              </TitleH3>
              <div className="text">
                {
                  lang[
                    "Bambizz allows you to send any package—of any size! Be it a bike, a bag, a suitcase, or anything else you've got, enter your package's dimensions in the package information, and see what offers you get from our couriers!"
                  ]
                }
              </div>
            </div>
            <div className="card">
              <ICSavingsPig className="icon" />
              <TitleH3 className="title" theme="sender">
                {lang["Everyone Benefits!"]}
              </TitleH3>
              <div className="text">
                {
                  lang[
                    "Everyone likes a win-win situation. Deliver packages on your way to or from work or for people in your area, or send packages with people in your vicinity. Either way, everyone wins with Bambizz crowdsourced delivery!"
                  ]
                }
              </div>
            </div>
            <div className="card">
              <ICPhoneConnection className="icon" />
              <TitleH3 className="title" theme="sender">
                {lang["One App to Send or Deliver!"]}
              </TitleH3>
              <div className="text">
                {
                  lang[
                    "Using the Bambizz app, anyone can send or deliver a package (see our Terms and Conditions for details). See what offers you'll get today!"
                  ]
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-us">
        <TitleH2 className="title-3">{lang["Contact Us"]}</TitleH2>
        <TitleH3 className="title-4">
          {
            lang[
              "At Bambizz, we do our best to make sure that you—our community of senders and couriers—can get those packages to their destinations easily and efficiently. Most of all, we're here to help both sides communicate clearly and effectively. \nWe'd love to hear from you. Happy delivering! \nThe Bambizz Team."
            ]
          }
        </TitleH3>
        <form className="contact-us-form">
          <div className="flex space-between">
            <Input
              className="name"
              placeholder={lang["Name"]}
              error={error.name}
              value={fields.name}
              onChange={(e) => setField("name", e.target.value)}
            />
            <Input
              className="phone"
              placeholder={lang["Phone"]}
              error={error.phone}
              phone
              type="tel"
              value={fields.phone}
              onChange={(e) => setField("phone", e.target.value)}
            />
          </div>
          <Input
            className="email"
            placeholder={lang["Email"]}
            error={error.email}
            type="email"
            value={fields.email}
            onChange={(e) => setField("email", e.target.value)}
          />
          <TextArea
            className="message"
            placeholder={lang["Your Message"]}
            error={error.description}
            value={fields.description}
            onChange={(e) => setField("description", e.target.value)}
          />
          {fields.description?.length > 5 && (
            <FAQComponent minScore={0.1} search={fields.description} showOne />
          )}
          <Button className="send" type="button" onClick={send}>
            {lang["Send Message"]}
          </Button>
        </form>
      </div>
    </HomePageDesktopStyle>
  );

  function send() {
    let err = {};
    Object.entries(fields || {}).forEach((ent) => {
      if (!ent[1]) {
        err[ent[0]] = true;
      }
      if (ent[0] === "email" && !validator.isEmail(ent[1])) {
        err[ent[0]] = true;
      }
      if (ent[0] === "name" && ent[1].split(" ").join("").length < 4) {
        err[ent[0]] = true;
      }
      if (ent[0] === "phone") {
        let phoneCheck = parsePhoneNumberFromString(ent[1], "IL");
        if (!phoneCheck) {
          err[ent[0]] = true;
        } else {
          err[ent[0]] = !parsePhoneNumberFromString(ent[1], "IL").isValid();
        }
      }
    });
    seterror(err);
    if (Object.values(err).some((val) => val)) {
      showMessage(dispatch, lang["error"], lang["enter-valid-fields"]);
      return;
    }
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(CONSTANTS.captcha, { action: "contactUs" })
        .then((token) => {
          onSend(fields, token);
          setfields({
            name: "",
            phone: "",
            email: "",
            description: "",
          });
        });
    });
  }

  function setField(name, val) {
    setfields({ ...fields, [name]: val });
  }
}

export const HomePageDesktopStyle = styled.main`
  padding: 15px 0;
  background-color: white;
  overflow-x: hidden;

  .send-now-button {
    margin: auto;
    width: 434px;
    text-align: center;
    margin-top: 40px;
    font-size: 24px;
    border-radius: 9px;
    padding: 17px 10px;
    background: none;
    border: solid 3px #393b45;
    border-radius: 10px;
    color: #393b45;
  }

  .login {
    padding: 12px 12px;
  }

  .image-1 {
    height: 156px;
    margin: auto;
    display: block;
    transform: translate(-70px, 0px);
  }

  .social-container {
    justify-content: center;
    display: flex;

    .facebook {
      margin: 0 20px;

      svg {
        width: 30px;
        height: 30px;
        fill: #888888;
      }
    }

    .instagram {
      margin: 0 20px;

      svg {
        width: 33px;
        height: 33px;
        fill: #888888;
        transform: translate(0px, -1px);
      }
    }
  }

  .store-buttons-con {
    width: 500px;
    margin: auto;
  }

  .terms-container {
    width: fit-content;
    margin: auto;
    text-align: center;
    margin-bottom: 50px;
    line-height: 43px;
  }

  .contact-us-form {
    margin: 40px;
    width: 700px;
    margin: auto;
    margin-top: 40px;
  }

  .name,
  .phone {
    height: 41px;
    flex: 0 0 43%;
    margin: 20px 0;
  }

  .email {
    height: 41px;
    flex: 0 0 43%;
    margin: 20px 0;
    margin-bottom: 40px;
  }

  .send {
    padding: 24px 98px;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 100px;
    background-color: #fff;
    border-color: #393b45;
    border-width: 3px;
    color: #393b45;
  }

  .row {
    display: flex;
    justify-content: space-between;

    .card {
      width: 313px;
      height: 285px;
      padding: 10px;
    }

    .icon {
      display: block;
      margin: auto;
      min-height: 62px;
    }

    .title {
      font-size: 21px;
      font-weight: bold;
      text-align: center;
      margin-top: 24px;
      margin-bottom: 17px;
      min-height: 56px;
    }

    .text {
      text-align: center;
    }
  }

  .table-container {
    max-width: 1000px;
    margin: auto;
    margin-top: 60px;
  }

  .title-1 {
    font-size: 55px;
    text-align: center;
    font-weight: bold;
    margin: 0 10%;
    color: #393b45;
  }

  .title-2 {
    text-align: center;
    font-size: 24px;
    color: #393b45;
  }

  .title-3 {
    font-size: 34px;
    color: #393b45;
    text-align: center;
    padding: 43px 0;
    font-weight: bold;
    padding-bottom: 34px;
  }

  .title-4 {
    font-size: 24px;
    color: #393b45;
    width: 710px;
    margin: auto;
    text-align: center;
  }

  .image-phone {
    height: 801px;
  }

  .image-3 {
    height: 238px;
    margin: 0 35px;
    transform: translate(0px, 100px);
  }

  .image-5 {
    height: 288px;
    align-self: flex-end;
    transform: translate(0px, -150px);
  }

  .images-3 {
    margin: 63px 0px;
  }

  .section-2 {
    margin: 40px 0;
  }

  .section-3 {
    background-color: #d8d9de36;
    padding-bottom: 30px;
  }

  .image-2 {
    height: 306px;
  }

  .store-button {
    &.link {
      border: none;
      padding: 10px;
    }

    margin: auto;
    margin-top: 50px;
    justify-content: space-between;
    background: none;
    padding: 0;
    width: unset;

    svg {
      width: 210px;
      height: 66px;
    }
  }

  .image-4 {
    height: 280px;
  }

  .select-lang {
    width: 100px;
    background-color: #00000000;
    border: none;
  }

  .name {
    line-height: 43px;
  }

  .logo {
    height: 72px;
    width: 200px;
    display: block;
    margin: auto;
    margin-top: 25%;
  }

  .menu-button {
    padding: 0 11px;
  }

  .sub-title {
    margin: 32px 50px;
    font-weight: 500;
    font-size: 20px;
  }

  .main-button {
    margin-bottom: 26px;
    padding: 13px;
    font-size: 22px;
    width: 260px;
    text-align: center;

    &.volunteer {
      font-size: 17px;
    }
  }

  .main-title {
    font-size: 29px;
  }
`;

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onSend: (fields, token) =>
    dispatch(
      sendContactUs({
        captcha: token,
        name: get(fields, "name", ""),
        phone: get(fields, "phone", ""),
        email: get(fields, "email", ""),
        type: get(fields, "type", ""),
        description: get(fields, "description", ""),
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePageDesktop);
