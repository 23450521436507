import React, { useEffect, useState } from "react";
import "./Style.css";
import getLang, { LanguageContext, LANGUAGES } from "./language/lang";
import { useRoutes, navigate, useInterceptor } from "hookrouter";
import { connect } from "react-redux";
import HomePage from "./pages/HomePage";
import HomePageDesktop from "./pages/HomePageDesktop";
import Modal from "./pages/Modal";
import Loader from "./pages/Loader";
import ContactUs from "./pages/ContactUs";
import MenuBarDesktop from "./pages/MenuBarDesktop";
import AboutUs from "./pages/AboutUs";
import Terms from "./pages/Terms";
import { get } from "lodash";
import { setSEO, getQueryParam } from "./common/function";
import SubscribePayments from "./pages/SubscribePayments";
import OnSuccessYaadPage from "./pages/OnSuccessYaadPage";
import DesktopApplication from "./pages/DesktopApplication";
import WhatsAppButton from "./pages/WhatsAppButton";
import FAQ from "./pages/FAQ";
import HomePageDesktopBusinesses from "./pages/HomePageDesktopBusinesses";
import HomePageBusinesses from "./pages/HomePageBusinesses";
import { logEvent } from "firebase/analytics";
import DeleteAccount from "./pages/DeleteAccount";
import { MainArticlesPage } from "./pages/amp_react/MainArticlesPage";
import ArticlePage from "./pages/amp_react/ArticlePage";
import { FooterDesktop } from "./pages/FooterDesktop";

function App({ modal, loader }) {
  const [mobile, setmobile] = useState(window.innerWidth < 980);
  useInterceptor(interceptFunction);
  useEffect(() => {
    try {
      setSEO(window.location.pathname.substr(3));
    } catch (err) {
      console.log(err);
    }
  }, []);
  useEffect(() => {
    let listener = () => {
      setmobile(window.innerWidth < 980);
    };
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  const routes = {
    "/": () => (mobile ? <HomePage /> : <HomePageDesktop />),
    "/businesses": () =>
      mobile ? <HomePageBusinesses /> : <HomePageDesktopBusinesses />,
    "/contact-us": () => <ContactUs />,
    "/about-us": () => <AboutUs />,
    "/delete-account": () => <DeleteAccount />,
    "/faq": () => <FAQ />,
    "/terms-and-conditions": () => <Terms />,
    "/subscribe-payments": () => <SubscribePayments />,
    "/yaad-success/thankYouPage": () => <OnSuccessYaadPage />,
    "/application/send-input-order": () => <DesktopApplication />,
    "/application/": () => <DesktopApplication />,
    "/application/:page": ({ page }) => <DesktopApplication page={page} />,
    "/articles/": () => <MainArticlesPage />,
    "/articles/:page": ({ page }) => <ArticlePage page={page} />,
  };
  const langRoutes = {};
  Object.values(LANGUAGES).forEach((lang) => {
    Object.entries(routes).forEach((ent) => {
      langRoutes["/" + lang.toLowerCase() + ent[0]] = ent[1];
    });
  });
  const routeResult = useRoutes(langRoutes);
  useEffect(() => {
    if (
      window.location.pathname === "/" &&
      getQueryParam("action") === "navigate"
    ) {
      let navTo = getQueryParam("payload");
      navTo = "/" + get(getLang(), "id", "en").toLowerCase() + "/" + navTo;
      if (["/he/", "/en/"].indexOf(navTo.substr(0, 4)) !== -1) {
        navigate(navTo);
      } else {
        navigate("/en/");
      }
    } else if (!routeResult) {
      let path = "/" + get(getLang(), "id", "en").toLowerCase() + "/";
      if (["/he/", "/en/"].indexOf(path) !== -1) {
        navigate(path);
      } else {
        navigate("/en/");
      }
    }
    if (
      window.location.pathname.substr(1, 3) !==
      get(getLang(), "id", "en").toLowerCase()
    ) {
      let newPath =
        "/" +
        get(getLang(), "id", "en").toLowerCase() +
        "/" +
        window.location.pathname.substr(4);
      if (["/he/", "/en/"].indexOf(newPath.substr(0, 4)) !== -1) {
        navigate(newPath);
      } else {
        navigate("/en/");
      }
    }
  }, []);
  let inApplication = window.location.pathname.indexOf("application") !== -1;
  return (
    <LanguageContext.Provider value={getLang()}>
      {!mobile && !inApplication && <MenuBarDesktop />}
      {modal && <Modal conf={modal} />}
      {loader && <Loader />}
      <style>{getLang().css}</style>
      <main>{routeResult || "404 not found"}</main>
      <WhatsAppButton mobile={mobile} />
      <FooterDesktop />
    </LanguageContext.Provider>
  );
  function interceptFunction(currentPath, nextPath) {
    try {
      setSEO(nextPath.substr(3));
      logEvent(window.analytics, "screen_view", {
        firebase_screen: nextPath.substr(3),
        firebase_screen_class: nextPath.substr(3),
      });
    } catch (err) {
      console.log(err);
    }
    return nextPath;
  }
}

const mapStateToProps = (state) => ({
  lang: state.lang,
  modal: state.modal,
  loader: state.loader,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(App);
