import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {
    ngramSimilarity,
} from "../common/function";
import {COLORS} from "../common/constants";
import {useThrottleCallback} from "@react-hook/throttle";
import { FQAItem } from "./FQAItem";

const formatAsHTML = faq => ({
    ...faq,
    rows: faq.rows.map(row => ({...row, content: <div dangerouslySetInnerHTML={{__html: row.content}}/>}))
})

const senderData = ({
    title: "שאלות נפוצות שולח",
    rows: [
        //sender faq
        {
            title: "האם אפשר לשלוח בבמביז משלוח או משלוחים מהיום להיום?",
            content: `בוודאי. בהזמנת משלוח, האפליקציה מאפשרת לכם מספר אפשרויות: <ol><li>משלוח מהיום להיום.</li><li>משלוח בהתאמה אישית של מועד המשלוח.</li><li>משלוח מהיום למחר.</li></ol>`,
        },
        {
            title: "מה זה משלוח מהיום להיום?",
            content: `משלוח מהיום להיום הינו שירות משלוח אקספרס בו שליח של במביז אוסף מכם את המשלוח אותו אתם מעוניינים לשלוח ומעביר אותו באותו יום ליעד אליו אתם מעוניינים לשלוח.`,
        },
        {
            title: "האם ניתן לשלוח בבמביז משלוחים בין עירוניים (מעיר לעיר)?",
            content:
                "בוודאי. המחיר בבמביז של משלוחים מעיר אחת לעיר אחרת (מהיום להיום, ומהיום למחר) הינו זול ואטרקטיבי ביחס לכל אפשרות אחרת בשוק.",
        },
        {
            title: "איך מבצעים הזמנת משלוח בבמביז?",
            content: `באפליקציה - מסמנים גודל חבילה, כתובת שולח וכתובת מקבל ושולחים בקלות.<br/>באתר -  בדף הבית. מקליקים על - שלח חבילה עכשיו, ומזמינים משלוח בקלות.<br/>אין לכם עדיין את אפליקציית המשלוחים במביז - הורידו עכשיו:<br/><a href="https://play.google.com/store/apps/details?id=com.bambizz" >גוגל פליי</a><br/><a href="https://apps.apple.com/app/id1509656952">אפל סטור</a>`,
        },
        {
            title: "מה זה משלוח מדלת לדלת?",
            content: `שירות משלוח מדלת לדלת המשמעות היא שהשליח אוסף את המשלוח מפתח ביתכם ומביא את המשלוח עד ליעד. בכל מקרה, גם השולח וגם המקבל לא צריך לצאת מפתח הבית אל השליח.`,
        },
        {
            title: "מחפש או מחפשת שליחות בתל אביב, בירושלים או בתוך כל עיר אחרת, האם במביז יכולה להתאים לי?",
            content: `בהחלט. במביז מתאימה למשלוחים בתוך העיר כמו גם למשלוחים מחוץ לעיר.`,
        },
        {
            title: "באיזו שעה אפשר לעלות משלוח לאפליקציית במביז?",
            content: `אפשר לעלות משלוח לאפליקציית במביז בכל שעה משעות היום והלילה. משלוחים אפשר לעלות 24 שעות. אנחנו ממליצים לעלות את המשלוח בשעות הבוקר. זה מעלה את הסיכוי למצוא שליח.`,
        },
        {
            title: "האם אפשר לשלוח משלוחים בבמביז בכל מקום בארץ?",
            content: `בהחלט. שירותי המשלוחים בבמביז הינם בכל רחבי הארץ ובמחיר אטרקטיבי.`,
        },
        {
            title: 'מה הכוונה כשכתוב באפליקציה "אזור מבצע"?',
            content: `"אזור מבצע" הכוונה הינה לאזור בו יש לבמביז שליחים רלוונטיים רבים ולכן המחיר שבמביז יכולה לתת הינו מחיר זול ואטרקטיבי במיוחד.<br/><br/>"אזור ללא מבצע" הינו אזור בו המחיר הינו אטרקטיבי אבל במביז עדיין מתפתחת באזור זה כדי לאפשר לכם שרות זול ומהיר יותר.`,
        },
        {
            title: "האם חייבים להיכנס לאתר של במביז כדי לבצע משלוחים באמצעות במביז?",
            content: `לבמביז אפליקצית משלוחים מעולה בעלת ממשק נוח במיוחד. כנסו לחנות האפליקציות, הורידו את במביז ותהנו מחווית משלוח מעולה.<br/>להורדת האפליקציה:<br/><a href="https://play.google.com/store/apps/details?id=com.bambizz" >גוגל פליי</a><br/><a href="https://apps.apple.com/app/id1509656952">אפל סטור</a>`,
        },
        {
            title: "האם ניתן לשלוח מסמכים בבמביז?",
            content: `בהחלט. במביז מבצעת משלוחים רבים של מסמכים. משלוחים לעורכי דין, לרואי חשבון, לעסקים קטנים ומשלוחים לאנשים פרטיים של מסמכים או כל סוג של משלוח.`,
        },
        {
            title: "האם במביז מותאמת לעסקים קטנים?",
            content: `במביז מתאימה מאוד לצרכיהם של עסקים קטנים.<br/><br/>בכל עסק קטן או גדול ישנן שליחויות שצריך לבצע אותן באופן מהיר, שליחויות אקספרס, שליחויות מהרגע להרגע (מעכשיו לעכשיו) או שליחויות מהיום להיום. במביז הינו המרקט פלייס שיאפשר לכם לאתר את השליח המתאים עבורם, בזמן ובמקום בו אתם צריכים אותו.`,
        },
        {
            title: `האם במביז מתאימה גם למשלוחים של אנשים פרטיים (שאינם חברות)?`,
            content: `כמובן. השליחים בבמביז הינם שירות משלוחים שמותאם גם לאנשים פרטיים וגם לחברות עסקיות ולכן במביז הינה חברת שליחויות המתאימה לחברות ולפרטיים כאחד.<br/><br/>בכל מקרה, המחיר שלנו הינו זול ואטרקטיבי והמשלוחים הם מהירים ומדלת לדלת.`,
        },
        {
            title: `סיימתי לעלות משלוח והמערכת מחפשת לי שליח מתאים, מה זה אומר?`,
            content: `לאחר העלאת המשלוח לאפליקציית במביז, אנחנו בבמביז שולחים הודעה לכל השליחים הרלוונטיים למשלוח שלכם. לפעמים אורך מספר שניות למצוא שליח, לפעמים מספר דקות ולפעמים אף מספר שעות. זאת מערכת אמיתית של שליחים ושולחים והמחיר המעולה שאתם מקבלים הוא בזכות החופש של השליחים לבחור את השליחויות שלהם. ככל שישנם יותר משלוחים בבמביז זמן התגובה של השליחים משתפר.`,
        },
        {
            title: `האם במביז מבצעים משלוח בין עירוני או עירוני בלבד?`,
            content: `בבמביז אפשר לעלות משלוח לכל מקום בארץ - בינעירוני (מעיר לעיר. לדוגמא - משלוח מירושלים לתל אביב,  או משלוח מתל אביב לחיפה וכדומה) ועירוני (בתוך העיר. לדוגמא- הזמנת שליח בכתובת בתל אביב לכתובת אחרת בתל אביב או מכתובת בירושלים לכתובת אחרת בירושלים).`,
        },
        {
            title: `האם במביז מתחייבים למצוא שליח לכל חבילה שמעלים לאפליקציה?`,
            content: `במביז שולחים הודעה עם המשלוח שלך למאות השליחים הרלוונטיים למשלוח הספציפי שהעליתם לאפליקציה. בדרך כלל נאתר עבורכם שליח שישמח לבצע את השליחות בה אתם מעוניינים. <br/><br/>עם זאת, יתכן שלא נמצא שליח שמתאים לכם. במקרה שלא מצאנו שליח לחבילה בתוך זמן סביר, החבילה תימחק מהמערכת. כמובן שככל שלא מצאנו שליח מתאים לא תחויבו בעלות כלשהי.`,
        },
        {
            title: `מה המחיר למשלוח חבילה?`,
            content: `הדרך היחידה לקבלת מחיר למשלוח חבילה הוא לבדוק באפליקציה או באתר את המחיר. המחיר למשלוח חבילה משתנה מחבילה לחבילה. המחיר נקבע על פי היעד, המרחק, הזמן, משקל החבילה ומשתנים נוספים באמצעות מערכת מתוחכמת של בינה מלאכותית (AI). בכל מקרה, המחיר של שליחות בבמביז יהיה זול ואטרקטיבי.`,
        },
        {
            title: `המערכת לא מצאה לכם שליח והשליחות חשובה לכם מה עושים?`,
            content: `היות ואנו מנסים להשיג עבורכם את המחיר הזול ביותר לשליחות מהירה מדלת לדלת, לעיתים לא נמצא שליח רלוונטי עבורכם במועד בו אתם מבקשים את השליחות. יש לנו מספר פתרונות אפשריים עבורכם:<ol><li>סו להעלות שוב את החבילה במועד מאוחר יותר.</li><li>נסו להעלות את החבילה בשליחות מהירה יותר (שליחות אקספרס מהיום להיום) כך שמחיר החבילה יהיה גבוה יותר ואטרקטיבי יותר עבור השליחים.</li><li>נסו להעלות את החבילה בשעות הבוקר.</li></ol>`,
        },
        {
            title: `האם אפשר לשלוח כל חבילה עם במביז?`,
            content: `במביז מציעה אפשרות למשלוח חבילות בגודל 2/5/10 ק"ג. חבילות גדולות יותר אפשר לשלוח תחת הקטגוריה "מותאם אישית".`,
        },
        {
            title: `יש לי חבילה גדולה למשלוח. האם בבמביז יש שליחים עם רכב?`,
            content: `בבמביז יש שליחים עם רכב.<br/><br/>כאשר אתם מזמינים שליחות שכדי לבצע אותה יש צורך ברכב (חבילה גדולה או רגישה במיוחד), ציינו בתיבה הנקראת "הערה לשליח" שמדובר בחבילה לה דרוש שליח עם רכב.`,
        },
        {
            title: `מה הכוונה "הערה לשליח"?`,
            content: `יש לכם חבילה שדורשת רכב? יש לכם חבילה מיוחדת כגון עוגת שוקולד או אופניים? הזמנים שלכם צריכים להתאים לאיסוף או לפיזור? אל תחסכו במילים, כתבו הכל בהערה לשליח. זה יחסוך לכם כאבי ראש עם שליחים לא מתאימים.`,
        },
        {
            title: `האם אפשר למחוק חבילה או לבטל חבילה?`,
            content: `ככל שהשליח לא אישר את החבילה לאיסוף אפשר לבטל את החבילה במקרים שבהם ישנה סיבה מיוחדת לביטול החבילה.<br/><br/>ככל שהשליח אישר את החבילה, לא ניתן לבטל את החבילה למעט מקרה שהשליח מאשר את ביטול החבילה.`,
        },
        {
            title: `באיזה מקרה אפשר לבטל חבילה לאחר שהשליח אישר איסוף?`,
            content: `כשהשליח מאשר חבילה הוא לפעמים כבר בדרך לאסוף את החבילה לכן השליח הוא היחיד שיכול לאשר את ביטול החבילה.`,
        },
        {
            title: `איך אפשר לדעת מהו סטטוס החבילה שלי?`,
            content: `כל חבילה מופיעה בתפריט תחת הקטגוריה "החבילות שלי". ישנן חבילות אשר פעילות עכשיו והם יופיעו פעמיים: גם תחת הקטגוריה - "החבילות שלי" וגם תחת הקטגוריה - "חבילות פעילות".`,
        },
    ],
});

const courierData = ({
    title: "שאלות נפוצות שליח",
    rows: [
        {
            title: `לפעמים אני רואה משלוחים ולפעמים לא. איך מגדירים את המשלוחים?`,
            content: `זה תלוי האם אתם רוצים לקבל משלוחים בכל הארץ או רק באזור שלכם. קחו בחשבון שכשאתם נרשמים למשלוחים רק באזור שלכם אתם מפסידים הרבה משלוחים שהם היו מתאימים לכם בנסיעות מסוימות. הגדרות- קבל הודעות על חבילות- להפעיל . קבלת הודעות מותאמות אישית- (כלומר רק באזור שלך) להפעיל או לכבות לפי הצורך.`,
        },
        {
            title: `האם לבמביז יש אלגוריתמים שמחלק משלוחים לפי דירוג לקוח ועד?`,
            content: `בבמביז אנחנו מעוניינים לתת ללקוח ולשליח את השירות למשלוח המקסימלי. לכן אנחנו מבצעים אופטימיזציה של הדירוג ועוד פרמטרים של השליח לפני שאנחנו שולחים לו חבילות לאישור.`,
        },
        {
            title: `אני רוצה להירשם כשליח מה עלי לעשות?`,
            content: `שליח יקר כל שעליך לעשות הוא להוריד את אפליקציית במביז, לוודא שאתם בממשק של השליח (הממשק הכתום) ולעקוב אחר ההוראות.`,
        },
        {
            title: `האם ביטול חבילות גורם לי לראות פחות חבילות?`,
            content: `כחלק משירות הלקוחות שלנו אנחנו ממפים את השליחים היעילים ביותר ועם כמות הביטולים הנמוכה ביותר. לכן כדאי מאוד לא לבטל חבילה למעט אם מדובר במקרה חירום ממש.`,
        },
        {
            title: `איך מבטלים חבילה?`,
            content: `בדף החבילה יש אייקון בחלק העליון בצורת פח אשפה. לחיצה עליו תאפשר לבטל את החבילה.כאשר השליח מבטל חבילה חובה עליו להזין סיבה לביטול.`,
        },
    ],
});

const technicalData = ({
    title: "שאלות לגבי תקלות טכניות",
    rows: [
        {
            title: `לא מצליחים לקבל קוד אימות ברישום לאפליקציה?`,
            content: `יש לוודא שאין לכם חסימה כלשהי על המכשיר כגון "חסימת הורים" "חסימת הכשר" ועוד. במידה והכל תקין ועדיין לא מקבלים קוד אימות, אפשר לנסות לקבל קוד אימות על ידי כניסה למערכת מהמחשב הביתי או מהאינטרנט בטלפון ולא דרך האפליקציה. בכל אופן צרו קשר ונשמח לטפל בכם אם לא הסתדרתם.`,
        },
        {
            title: `איך עוברים מאפליקציית שליח או שולח לאפליקציית שליח או שולח?`,
            content: `בכל אחת מהאפליקציות בחלק העליון ישנו ריבוע עם שלושה קווים. זהו תפריט הבית  לחיצה עליו ובחלקו התחתון יש אפשרות לעבור לאפליקציית שליח או שולח.`,
        },
        {
            title: `איך אני יודע אם אני באפליקציית שליח או אפליקציית שולח?`,
            content: `האייקון של אפליקציית שליח ושולח זהה והוא בצבע כתום עם האות B במרכז האפליקציות עצמם שונות, אפליקציה כתומה- אפליקציית שליח (השליח הוא או היא באים לקחת את החבילה) <br/><br/>אפליקציה תכלת – אפליקציית שולח (השולח הוא או היא הם רוצים למסור את החבילה לשליח שימסור אותה בעבורם לנקודה אחרת)`,
        },
    ],
});

const styles = {
    titleTextColor: COLORS.mainColorSender,
    rowTitleColor: COLORS.mainColorSender,
    rowContentPaddingBottom: "10px",
};

const config = {
    animate: true,
};

const filterSearch = (search, data, minScore = 0.2) => {
    return {
        ...data,
        rows: data.rows
            .sort((a, b) => {
                const scoreA = Math.max(
                    ngramSimilarity(search, a.title),
                    ngramSimilarity(search, a.content)
                );
                const scoreB = Math.max(
                    ngramSimilarity(search, b.title),
                    ngramSimilarity(search, b.content)
                );
                return scoreB - scoreA;
            })
            .filter(
                (row) =>
                    ngramSimilarity(search, row.title) > minScore ||
                    ngramSimilarity(search, row.content) > minScore
            )
            .slice(0, 5),
    };
};

function FAQComponent({search, minScore, showOne, addSEO}) {
    const [senderQuestions, setsenderQuestions] = useState(senderData);
    const [courierQuestions, setcourierQuestions] = useState(courierData);
    const [technicalQuestions, settechnicalQuestions] = useState(technicalData);
    const [allInOne, setallInOne] = useState({
        title: "שאלות נפוצות",
        rows: [
            ...senderData.rows,
            ...courierData.rows,
            ...technicalData.rows,
        ],
    });

    const applySearch = useThrottleCallback(
        () => {
            if (!search || search.length < 3) {
                setsenderQuestions(senderData);
                setcourierQuestions(courierData);
                settechnicalQuestions(technicalData);
                setallInOne({
                    title: "שאלות נפוצות",
                    rows: [
                        ...senderData.rows,
                        ...courierData.rows,
                        ...technicalData.rows,
                    ],
                });
            } else {
                setsenderQuestions(filterSearch(search, senderData, minScore));
                setcourierQuestions(filterSearch(search, courierData, minScore));
                settechnicalQuestions(filterSearch(search, technicalData, minScore));
                setallInOne(
                    filterSearch(
                        search,
                        {
                            title: "שאלות נפוצות",
                            rows: [
                                ...senderData.rows,
                                ...courierData.rows,
                                ...technicalData.rows,
                            ],
                        },
                        minScore
                    )
                );
            }
        },
        [search, minScore],
        5
    );
    useEffect(() => {
        applySearch();
    }, [search]);
    useEffect(() => {
        if (addSEO) {
            const script = document.createElement("script");
            script.id = "google-rich-results";
            script.type = "application/ld+json";
            script.innerText = JSON.stringify({
                "@context": "https://schema.org",
                "@type": "FAQPage",
                mainEntity: [
                    ...senderData.rows,
                    ...courierData.rows,
                    ...technicalData.rows,
                ].map((row) => ({
                    "@type": "Question",
                    name: row.title,
                    acceptedAnswer: {
                        "@type": "Answer",
                        text: row.content,
                    },
                })),
            });
            document.getElementsByTagName("head")[0].appendChild(script);
        }
    }, [addSEO]);
    return (
        <FAQStyle>
            {!showOne && senderQuestions.rows.length ? (
                <FQAItem data={formatAsHTML(senderQuestions)} styles={styles} config={config}/>
            ) : null}
            {!showOne && courierQuestions.rows.length ? (
                <FQAItem data={formatAsHTML(courierQuestions)} styles={styles} config={config}/>
            ) : null}
            {!showOne && technicalQuestions.rows.length ? (
                <FQAItem data={formatAsHTML(technicalQuestions)} styles={styles} config={config}/>
            ) : null}
            {showOne && allInOne.rows.length ? (
                <FQAItem data={formatAsHTML(allInOne)} styles={styles} config={config}/>
            ) : null}
        </FAQStyle>
    );
}

export const FAQStyle = styled.div`
  .container {
    padding: 0;
  }

  .faq-container {
    margin: 10px;
  }

  .small-small {
    color: grey;
    font-size: 12px;
    color: #ff9e71;
    text-align: center;
  }

  .send-now-button {
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 24px;
    border-radius: 9px;
    padding: 17px 10px;
    background: none;
    border: solid 3px #393b45;
    border-radius: 10px;
    color: #393b45;
  }

  .main-title {
    color: #70c9db;
    font-size: 33px;
  }

  .second-title {
    margin-bottom: 40px;
    text-align: center;
    color: #70c9db;
  }

  .align-bottom {
    align-items: baseline;
    margin: 40px 0;
  }

  .small-text {
    font-size: 64px;
    margin: 0;
    padding: 0;
    color: #70c9db;
  }

  .price-text {
    font-size: 70px;
    line-height: 92px;
    margin: 0;
    padding: 0;
    color: #70c9db;
    font-weight: 600;
  }

  .text {
    margin: 30px 0;
  }

  svg {
    fill: #393b45;
  }

  .logo {
    display: block;
    margin: auto;
    width: 160px;
    height: 100px;
  }

  .hidden {
    visibility: hidden;
  }

  .flex46 {
    flex: 0 0 46%;
  }

  textarea {
    height: 57px;
  }

  label {
    display: block;
    margin: 3px 2px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: 16px;
  }

  .main-title {
    text-align: center;
    font-size: 33px;
    margin: 18px 0px;
  }

  .get-price {
    margin: auto;
    margin-top: 40px;
  }

  .row-content-text {
    padding: 7px 17px !important;
  }

  @media only screen and (min-width: 980px) {
    background-color: white;
    .faq-container {
      margin: auto;
      width: 800px;
    }

    .flex {
      justify-content: center;
    }

    .close {
      display: none;
    }

    .main-title {
      font-size: 50px;
      margin-top: 0px;
    }

    .text {
      width: 800px;
      margin: auto;
      font-size: 20px;
    }

    .price-text {
      font-size: 150px;
    }
  }
`;

export default FAQComponent;
