import React from "react";
import ArticlesPage from "./ArticlesPage";
import { LanguageContext } from "../../language/lang";
import './styles.css';

export const MainArticlesPage = () => {
  const lang = React.useContext(LanguageContext);

  return <ArticlesPage language={lang.id} />;
};
