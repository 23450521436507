import React, { useState } from "react";
import { IconButton, TitleH2 } from "./Components";
import {
  FaChevronDown,
  FaChevronLeft,
} from "react-icons/fa";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

const Question = ({ question, answer }) => {
  const [open, setOpen] = useState(false);
  const [id] = useState(() => uuidv4());

  return (
    <>
      <IconButton
        className="faq-question"
        aria-controls={id}
        aria-expanded={open}
        onClick={() => setOpen((old) => !old)}
      >
        {!open ? <FaChevronLeft /> : <FaChevronDown />}
        <span>{question}</span>
      </IconButton>
      {open && <p id={id}>{answer}</p>}
      <hr />
    </>
  );
};

export const FQAItem = ({ data }) => {
  return (
    <FaqStyle>
      <TitleH2>{data.title}</TitleH2>
      <hr />
      {data.rows.map((row, i) => (
        <Question key={i} question={row.title} answer={row.content} />
      ))}
    </FaqStyle>
  );
};

const FaqStyle = styled.div`
  svg {
    height: 24px;
  }

  @media only screen and (max-width: 500px) {
    .faq-question {
      span {
        display: block;
        max-width: calc(100% - 40px);
      }
    }
  }
`;
