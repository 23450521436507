import React, {useContext, useState} from "react";
import styled from "styled-components";
import { Input, IconButton, A, TitleH1} from "./Components";
import {LanguageContext} from "../language/lang";
import {ICLogo} from "./Icons";
import {getDynemicLink, LINK_TYPE} from "../common/function";
import {MdClose} from "react-icons/md";
import {navigateTo} from "../common/function";
import FAQComponent from "./FAQComponent";

function FAQ() {
    const lang = useContext(LanguageContext);
    const [search, setsearch] = useState("");

    return (
        <FAQStyle>
            <div className="flex" style={{alignItems: "center"}}>
                <IconButton className="close" title={lang.close}>
                    <MdClose onClick={() => navigateTo("/")}/>
                </IconButton>
            </div>
            <div className="container">
                <ICLogo className="logo"/>
                <div className="faq-container">
                    <TitleH1>
                        בבמביז תוכלו להזמין שליח פרטי עבורכם ולשלוח חבילות, מסמכים או כל דבר אחר. בבמביז קיימים שליחים
                        עם קטנוע ושליחים עם רכב.
                    </TitleH1>
                    <Input
                        style={{marginTop: "10px"}}
                        value={search}
                        onChange={(e) => setsearch(e.target.value)}
                        placeholder={lang["search"]}
                    />
                    <FAQComponent search={search} addSEO/>
                </div>
                <A
                    title={lang["send-now"]}
                    className="send-now-button"
                    realHref={getDynemicLink(LINK_TYPE.SEND_NOW, lang.id)}
                    href="https://bambizz.com/he/application/sender-input-order-1"
                >
                    {lang["send-now"]}
                </A>
            </div>
        </FAQStyle>
    );
}

export const FAQStyle = styled.div`
  .container {
    padding: 0;
  }

  .faq-container {
    margin: 10px;
  }

  .small-small {
    color: grey;
    font-size: 12px;
    color: #ff9e71;
    text-align: center;
  }

  .send-now-button {
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 24px;
    border-radius: 9px;
    padding: 17px 10px;
    background: none;
    border: solid 3px #393b45;
    border-radius: 10px;
    color: #393b45;
  }

  .main-title {
    color: #70c9db;
    font-size: 33px;
  }

  .second-title {
    margin-bottom: 40px;
    text-align: center;
    color: #70c9db;
  }

  .align-bottom {
    align-items: baseline;
    margin: 40px 0;
  }

  .small-text {
    font-size: 64px;
    margin: 0;
    padding: 0;
    color: #70c9db;
  }

  .price-text {
    font-size: 70px;
    line-height: 92px;
    margin: 0;
    padding: 0;
    color: #70c9db;
    font-weight: 600;
  }

  .text {
    margin: 30px 0;
  }

  svg {
    fill: #393b45;
  }

  .logo {
    display: block;
    margin: auto;
    width: 160px;
    height: 100px;
  }

  .hidden {
    visibility: hidden;
  }

  .flex46 {
    flex: 0 0 46%;
  }

  textarea {
    height: 57px;
  }

  label {
    display: block;
    font-weight: bold;
    margin: 20px 2px 10px;
    font-size: 16px;
  }

  .main-title {
    text-align: center;
    font-size: 33px;
    margin: 18px 0;
  }

  .get-price {
    margin: 40px auto auto;
  }

  .row-content-text {
    padding: 7px 17px !important;
  }

  @media only screen and (min-width: 980px) {
    background-color: white;
    .faq-container {
      margin: auto;
      width: 800px;
    }

    .flex {
      justify-content: center;
    }

    .close {
      display: none;
    }

    .main-title {
      font-size: 50px;
      margin-top: 0;
    }

    .text {
      width: 800px;
      margin: auto;
      font-size: 20px;
    }

    .price-text {
      font-size: 150px;
    }
  }
`;

export default FAQ;
