import React, { useEffect, useContext, useState } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import {
  Button,
  TextArea,
  Input,
  IconButton,
  TitleH1,
  TitleH2,
} from "./Components";
import { LanguageContext } from "../language/lang";
import { sendContactUs } from "../redux/actions";
import { get } from "lodash";
import { reportPageView, showMessage } from "../common/function";
import { MdClose } from "react-icons/md";
import { CONSTANTS } from "../common/constants";
import { navigateTo } from "../common/function";
import validator from "validator";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import FAQComponent from "./FAQComponent";

function ContactUs({ onSend }) {
  const lang = useContext(LanguageContext);
  const dispatch = useDispatch();
  const [fields, setfields] = useState({
    name: "",
    phone: "",
    email: "",
    description: "",
  });
  const [error, seterror] = useState({});
  useEffect(() => {
    let element = document.getElementById("hide-recaptcha");
    element.innerText = "";
    reportPageView("/contact-us");
    return () => {
      let element = document.getElementById("hide-recaptcha");
      element.innerText = ".grecaptcha-badge{visibility: hidden;}";
    };
  }, []);
  return (
    <ContactUsStyle>
      <div className="flex header-container">
        <IconButton className="close" title={lang.close}>
          <MdClose onClick={() => navigateTo("/")} />
        </IconButton>
        <TitleH1 theme="sender" className="main-title">
          {lang["Contact Us"]}
        </TitleH1>
      </div>
      <div className="contact-us">
        <TitleH2 className="title-4" style={{ color: "black" }}>
          {
            lang[
              "At Bambizz, we do our best to make sure that you—our community of senders and couriers—can get those packages to their destinations easily and efficiently. Most of all, we're here to help both sides communicate clearly and effectively. \nWe'd love to hear from you. Happy delivering! \nThe Bambizz Team."
            ]
          }
        </TitleH2>
        <form className="contact-us-form">
          <div className="flex space-between">
            <Input
              className="name"
              placeholder={lang["Name"]}
              error={error.name}
              value={fields.name}
              onChange={(e) => setField("name", e.target.value)}
            />
            <Input
              className="phone"
              placeholder={lang["Phone"]}
              error={error.phone}
              phone
              type="tel"
              value={fields.phone}
              onChange={(e) => setField("phone", e.target.value)}
            />
          </div>
          <Input
            className="email"
            placeholder={lang["Email"]}
            error={error.email}
            type="email"
            value={fields.email}
            onChange={(e) => setField("email", e.target.value)}
          />
          <TextArea
            className="message"
            placeholder={lang["Your Message"]}
            error={error.description}
            value={fields.description}
            onChange={(e) => setField("description", e.target.value)}
          />
          {fields.description?.length > 5 && (
            <FAQComponent minScore={0.1} search={fields.description} showOne />
          )}
          <Button className="send" type="button" onClick={send}>
            {lang["Send Message"]}
          </Button>
        </form>
      </div>
    </ContactUsStyle>
  );

  function send() {
    let err = {};
    Object.entries(fields || {}).forEach((ent) => {
      if (!ent[1]) {
        err[ent[0]] = true;
      }
      if (ent[0] === "email" && !validator.isEmail(ent[1])) {
        err[ent[0]] = true;
      }
      if (ent[0] === "name" && ent[1].split(" ").join("").length < 4) {
        err[ent[0]] = true;
      }
      if (ent[0] === "phone") {
        let phoneCheck = parsePhoneNumberFromString(ent[1], "IL");
        if (!phoneCheck) {
          err[ent[0]] = true;
        } else {
          err[ent[0]] = !parsePhoneNumberFromString(ent[1], "IL").isValid();
        }
      }
    });
    seterror(err);
    if (Object.values(err).some((val) => val)) {
      showMessage(dispatch, lang["error"], lang["enter-valid-fields"]);
      return;
    }
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(CONSTANTS.captcha, { action: "contactUs" })
        .then((token) => {
          onSend(fields, token);
        });
    });
  }

  function setField(name, val) {
    setfields({ ...fields, [name]: val });
  }
}

export const ContactUsStyle = styled.div`
  min-height: 100%;

  .flex {
    flex-direction: column;
  }

  .title-4 {
    display: none;
  }

  .contact-us-form {
    padding: 0px 15px;
    margin: 40px;
    margin: auto;
  }

  .name,
  .phone {
    height: 41px;
    flex: 0 0 43%;
    margin: 20px 0;
  }

  .email {
    flex: 0 0 43%;
    margin: 20px 0;
    margin-bottom: 40px;
  }

  .send {
    padding: 24px 98px;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 100px;
    background-color: #fff;
    border-color: #393b45;
    border-width: 3px;
    color: #393b45;
  }

  .container {
    padding: 5%;
    padding-top: 0;
  }

  svg {
    fill: #393b45;
  }

  .hidden {
    visibility: hidden;
  }

  .flex46 {
    flex: 0 0 46%;
  }

  textarea {
    height: 57px;
  }

  label {
    display: block;
    margin: 3px 2px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: 16px;
  }

  .main-title {
    text-align: center;
    font-size: 22px;
    margin: 18px 0px;
  }

  .get-price {
    margin: auto;
    margin-top: 40px;
  }

  @media only screen and (min-width: 980px) {
    background-color: white;
    .contact-us-form {
      margin-top: 40px;
    }

    .flex {
      flex-direction: row;
    }

    .title-4 {
      display: block;
      width: 700px;
      font-size: 18px;
      text-align: center;
      margin: auto;
    }

    .email {
      height: 41px;
    }

    .contact-us-form {
      width: 700px;
    }

    .header-container {
      justify-content: center;
    }

    .close,
    .logo {
      display: none;
    }

    .main-title {
      font-size: 50px;
      margin-top: 50px;
    }

    .container {
      width: 700px;
      margin: auto;
      margin-bottom: 100px;
    }
  }
`;

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onSend: (fields, token) =>
    dispatch(
      sendContactUs({
        captcha: token,
        name: get(fields, "name", ""),
        phone: get(fields, "phone", ""),
        email: get(fields, "email", ""),
        type: get(fields, "type", ""),
        description: get(fields, "description", ""),
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
