export const CONSTANTS = {
    version: '3.1.3',
    captcha: '6LeA4uQUAAAAAFdBK7qSEaMbvKefCvsFtjbgJllF',
}

export const COLORS = {
    mainColorSender: '#70c9db',
    mainColorCourier: '#ff9e71',
    filledButtonText: '#ffffff',
    lightBorder: '#D8D9DE',
    inputText: '#393B45',
    status: '#70c9db',
    disabled: '#cacaca',
    senderBackground: '#f7e0ff',
    courierBackground: '#fff6e6',
    backgroundColor: '#F9F5Fa',
    covidMainColor:'#70c9db',
}

export const COUNTRY_CODES = {
    'Israel': '+972',
    'United States of America': '+1',
    'Afghanistan': '+93',
    'Albania': '+355',
    'Algeria': '+213',
    'American Samoa': '+1684',
    'Andorra': '+376',
    'Angola': '+244',
    'Anguilla': '+1264',
    'Antigua and Barbuda': '+1268',
    'Argentina': '+54',
    'Armenia': '+374',
    'Aruba': '+297',
    'Australia': '+61',
    'Austria': '+43',
    'Azerbaijan': '+994',
    'Bahamas': '+1242',
    'Bahrain': '+973',
    'Bangladesh': '+880',
    'Barbados': '+1246',
    'Belarus': '+375',
    'Belgium': '+32',
    'Belize': '+501',
    'Benin': '+229',
    'Bermuda': '+1441',
    'Bhutan': '+975',
    'Bolivia': '+591',
    'Bosnia and Herzegovina': '+387',
    'Botswana': '+267',
    'Brazil': '+55',
    'Brunei Darussalam': '+673',
    'Bulgaria': '+359',
    'Burkina Faso': '+226',
    'Burundi': '+257',
    'Cambodia': '+855',
    'Cameroon': '+237',
    'Canada': '+1',
    'Cape Verde': '+238',
    'Cayman Islands': '+1345',
    'Central African Republic': '+236',
    'Chad': '+235',
    'Chile': '+56',
    'China': '+86',
    'Christmas Island': '+618',
    'Cocos (Keeling) Islands': '+618',
    'Colombia': '+57',
    'Comoros': '+269',
    'Congo (Brazzaville)': '+242',
    'Congo(Kinshasa)': '+243',
    'Cook Islands': '+682',
    'Costa Rica': '+506',
    'Côte D\'Ivoire (Ivory Coast)': '+225',
    'Croatia (Hrvatska)': '+385',
    'Cuba': '+53',
    'Cyprus': '+357',
    'Czech Republic': '+420',
    'Denmark': '+45',
    'Djibouti': '+253',
    'Dominica': '+1767',
    'Dominican Republic (809)': '+1809',
    'Dominican Republic (829)': '+1829',
    'Dominican Republic (849)': '+1849',
    'Ecuador': '+593',
    'Egypt': '+20',
    'El Salvador': '+503',
    'Equatorial Guinea': '+240',
    'Eritrea': '+291',
    'Estonia': '+372',
    'Ethiopia': '+251',
    'Falkland Islands (Malvinas)': '+500',
    'Faroe Islands': '+298',
    'Fiji': '+679',
    'Finland': '+358',
    'France': '+33',
    'French Guiana': '+594',
    'French Polynesia': '+689',
    'Gabon': '+241',
    'Gambia': '+220',
    'Georgia': '+995',
    'Germany': '+49',
    'Ghana': '+233',
    'Gibraltar': '+350',
    'Greece': '+30',
    'Greenland': '+299',
    'Grenada': '+1	473	',
    'Guadeloupe': '+590',
    'Guam': '+1	671	',
    'Guatemala': '+502',
    'Guinea': '+224',
    'Guinea-Bissau': '+245',
    'Guyana': '+592',
    'Haiti': '+509',
    'Holy See (Vatican City State)': '+379',
    'Honduras': '+504',
    'Hong Kong, SAR': '+852',
    'Hungary': '+36',
    'Iceland': '+354',
    'India': '+91',
    'Indonesia': '+62',
    'Iran, Islamic Republic of': '+98',
    'Iraq': '+964',
    'Ireland': '+353',
    'Italy': '+39',
    'Jamaica': '+1876	',
    'Japan': '+81',
    'Jordan': '+962',
    'Kazakhstan (76)': '+76',
    'Kazakhstan (77)': '+77',
    'Kenya': '+254',
    'Kiribati': '+686',
    'Korea, Democratic People\'s Republic of (North)': '+850',
    'Korea, Republic of(South)': '+82',
    'Kuwait': '+965',
    'Kyrgyzstan': '+996',
    'Laos (Lao PDR)': '+856',
    'Latvia': '+371',
    'Lebanon': '+961',
    'Lesotho': '+266',
    'Liberia': '+231',
    'Libya': '+218',
    'Liechtenstein': '+423',
    'Lithuania': '+370',
    'Luxembourg': '+352',
    'Macao (SAR China)': '+853',
    'Macedonia, Republic of': '+389',
    'Madagascar': '+261',
    'Malawi': '+265',
    'Malaysia': '+60',
    'Maldives': '+960',
    'Mali': '+223',
    'Malta': '+356',
    'Marshall Islands': '+692',
    'Martinique': '+596',
    'Mauritania': '+222',
    'Mauritius': '+230',
    'Mayotte': '+262',
    'Mexico': '+52',
    'Micronesia, Federated States of': '+691',
    'Moldova': '+373',
    'Monaco': '+377',
    'Mongolia': '+976',
    'Montenegro': '+382',
    'Montserrat': '+1664',
    'Morocco and Western Sahara': '+212',
    'Mozambique': '+258',
    'Myanmar': '+95',
    'Namibia': '+264',
    'Nauru': '+674',
    'Nepal': '+977',
    'Netherlands': '+31',
    'Netherlands Antilles': '+599',
    'New Caledonia': '+687',
    'New Zealand': '+64',
    'Nicaragua': '+505',
    'Niger': '+227',
    'Nigeria': '+234',
    'Niue': '+683',
    'Norfolk Island': '+672',
    'Northern Mariana Islands': '+1670',
    'Norway': '+47',
    'Oman': '+968',
    'Pakistan': '+92',
    'Palau': '+680',
    'Palestinian Territory': '+970',
    'Panama': '+507',
    'Papua New Guinea': '+675',
    'Paraguay': '+595',
    'Peru': '+51',
    'Philippines': '+63',
    'Pitcairn': '+870',
    'Poland': '+48',
    'Portugal': '+351',
    'Puerto Rico (787)': '+1787',
    'Puerto Rico (939)': '+1939',
    'Qatar': '+974',
    'Réunion and Mayotte': '+262',
    'Romania': '+40',
    'Russian Federation': '+7',
    'Rwanda': '+250',
    'Saint Helena and also Tristan Da Cunha': '+290',
    'Saint Kitts and Nevis': '+1869',
    'Saint Lucia': '+1758',
    'Saint Pierre and Miquelon': '+508',
    'Saint Vincent and the Grenadines': '+1784',
    'Samoa': '+685',
    'San Marino': '+378',
    'São Tomé and Principe': '+239',
    'Saudi Arabia': '+966',
    'Senegal': '+221',
    'Serbia': '+381',
    'Seychelles': '+248',
    'Sierra Leone': '+232',
    'Singapore': '+65',
    'Slovakia': '+421',
    'Slovenia': '+386',
    'Solomon Islands': '+677',
    'Somalia': '+252',
    'South Africa': '+27',
    'Spain': '+34',
    'Sri Lanka': '+94',
    'Sudan': '+249',
    'Suriname': '+597',
    'Svalbard and Jan Mayen Islands': '+47',
    'Swaziland': '+268',
    'Sweden': '+46',
    'Switzerland': '+41',
    'Syrian Arab Republic (Syria)': '+963',
    'Taiwan, Republic of China': '+886',
    'Tajikistan': '+992',
    'Tanzania, United Republic of': '+255',
    'Thailand': '+66',
    'Timor-Leste': '+670',
    'Togo': '+228',
    'Tokelau': '+690',
    'Tonga': '+676',
    'Trinidad and Tobago': '+1868',
    'Tunisia': '+216',
    'Turkey': '+90',
    'Turkmenistan': '+993',
    'Turks and Caicos Islands': '+1649',
    'Tuvalu': '+688',
    'Uganda': '+256',
    'Ukraine': '+380',
    'United Arab Emirates': '+971',
    'United Kingdom': '+44',
    'Uruguay': '+598',
    'Uzbekistan': '+998',
    'Vanuatu': '+678',
    'Venezuela (Bolivarian Republic of)': '+58',
    'Viet Nam': '+84',
    'Virgin Islands, British': '+1284',
    'Virgin Islands, US': '+1340',
    'Wallis and Futuna Islands': '+681',
    'Yemen': '+967',
    'Zambia': '+260',
    'Zimbabwe': '+263',


}
