import React, { useEffect } from "react";
import styled from "styled-components";
import { Title } from "./Components";
import { reportPageView } from "../common/function";

function SubscribePayments() {

  useEffect(() => {
    reportPageView("/advertisment-0001");
  }, []);
  return (
    <SubscribePaymentsStyle>
      <div className="container">
        <Title className="main-title">להמשך הרשמה ניצור איתכם קשר בקרוב</Title>
        <Title className="main-title">תודה רבה</Title>
        <Title className="small-text">
          על מנת לסיים את תהליך ההרשמה יש לסגור את החלון
        </Title>
      </div>
    </SubscribePaymentsStyle>
  );
}

export const SubscribePaymentsStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .container {
    padding: 5%;
  }
  .small-small {
    color: grey;
    font-size: 12px;
    color: #ff9e71;
    text-align: center;
  }
  .main-title {
    color: #70c9db;
    font-size: 33px;
  }
  .second-title {
    margin-bottom: 40px;
    text-align: center;
    color: #70c9db;
  }
  .align-bottom {
    align-items: baseline;
    margin: 40px 0;
  }
  .small-text {
    font-size: 16px;
    margin: 0;
    padding: 0;
    text-align: center;
    color: #70c9db;
  }
  .price-text {
    font-size: 70px;
    line-height: 92px;
    margin: 0;
    padding: 0;
    color: #70c9db;
    font-weight: 600;
  }
  .text {
    margin: 30px 0;
  }
  svg {
    fill: #393b45;
  }
  .logo {
    display: block;
    margin: auto;
  }
  .hidden {
    visibility: hidden;
  }
  .flex46 {
    flex: 0 0 46%;
  }
  textarea {
    height: 57px;
  }
  label {
    display: block;
    margin: 3px 2px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: 16px;
  }
  .main-title {
    text-align: center;
    font-size: 33px;
    margin: 18px 0px;
  }
  .get-price {
    margin: auto;
    margin-top: 40px;
  }
  @media only screen and (min-width: 980px) {
    background-color: white;
    .flex {
      justify-content: center;
    }
    .close {
      display: none;
    }
    .main-title {
      font-size: 50px;
      margin-top: 50px;
    }
    .text {
      width: 800px;
      margin: auto;
      font-size: 20px;
    }
    .price-text {
      font-size: 150px;
    }
  }
`;

export default SubscribePayments;
