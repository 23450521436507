import { openModal, closeModal } from "../redux/actions";
import getLang from "../language/lang";
import { get } from "lodash";
import { navigate } from "hookrouter";
import { nGram } from "n-gram";

export const getQueryParam = (variable) => {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return "";
};

export const setSEO = (path) => {
  try {
    let seo = getLang()[path.substr(3)];
    console.log(seo);
    if (!seo || typeof seo !== "object") {
      seo = getLang()[path];
    }
    console.log(seo);
    if (!seo || typeof seo !== "object") {
      seo = getLang()["/"];
    }
    console.log(seo);
    document.getElementById("page-title").innerText = get(
      seo,
      "title",
      "Bambizz"
    );
    document.getElementById("description").content = get(
      seo,
      "description",
      ""
    );
    document.getElementById("he-alternate").href = get(
      window,
      "location.href",
      ""
    ).replace("/en/", "/he/");
    document.getElementById("en-alternate").href = get(
      window,
      "location.href",
      ""
    ).replace("/he/", "/en/");
  } catch (e) {}
};

export const setCustomSEO = (title, description) => {
  try {
    document.getElementById("page-title").innerText = title || "Bambizz";
    document.getElementById("description").content = description;
    document.getElementById("he-alternate").href = get(
      window,
      "location.href",
      ""
    ).replace("/en/", "/he/");
    document.getElementById("en-alternate").href = get(
      window,
      "location.href",
      ""
    ).replace("/he/", "/en/");
  } catch (e) {}
};

export const navigateTo = (path, replace, queryParams) => {
  navigate(
    "/" + get(getLang(), "id", "en").toLowerCase() + path,
    replace,
    queryParams
  );
};

export const apiRequest = async (
  url,
  data,
  method = "GET",
  resType = "text"
) => {
  let res = await fetch(url, {
    method: method,
    body: method === "POST" ? JSON.stringify(data) : undefined,
    headers: {
      "content-type": "application/json",
    },
  }).catch((err) => {
    return {
      success: false,
      message: err.message + " " + getLang()["No Connection"],
      code: "No connection",
    };
  });
  if (!res.ok) {
    return {
      success: false,
      message: res.status + " " + getLang()["No Connection"],
      code: res.status,
    };
  }
  if (resType === "text") {
    let text = await res.text();
    try {
      return JSON.parse(text);
    } catch (err) {
      return { success: false, message: err.message, code: res.code };
    }
  } else {
    let blob = await res.blob();
    return blob;
  }
};

export const showMessage = (dispatch, title, message) => {
  const modalConf = {
    title: title,
    text: message,
    button1: {
      text: getLang()["Close"],
      onClick: () => {
        dispatch(closeModal());
      },
    },
  };
  dispatch(openModal(modalConf));
};

export const reportError = async (error, uid) => {
  console.error(
    "message: " +
      get(error, "message", "") +
      ", toString(): " +
      error.toString(),
    get(error, "name", "")
  );
  try {
    window.ga(
      "send",
      "event",
      "exception",
      (uid ? "uid: " + uid + " ," : "") +
        "message: " +
        get(error, "message", ""),
      error.toString()
    );
  } catch (err) {
    console.error(err);
  }
};

export const reportPageView = async (page) => {
  try {
    //  window.ga("send", "pageview", page);
  } catch (err) {
    console.error(err);
  }
};

export const reportGooglePlayClick = async () => {
  return new Promise((resolve) => {
    try {
      window.gtag("event", "conversion", {
        send_to: "AW-631207360/BGyuCMOW7d4DEMDr_awC",
        value: 5.0,
        currency: "ILS",
        event_callback: resolve,
      });
      //logEvent(window.analytics, "click_google_play", {
      //  value: 5,
      //});
      setTimeout(resolve, 1500);
    } catch (err) {
      console.error(err);
      resolve();
    }
  });
};

export const reportAppStoreClick = async () => {
  return new Promise((resolve) => {
    try {
      window.gtag("event", "conversion", {
        send_to: "AW-631207360/yWYLCIX2uN4DEMDr_awC",
        value: 5.0,
        currency: "ILS",
        event_callback: resolve,
      });
      setTimeout(resolve, 1500);
      //logEvent(window.analytics, "click_app_store", {
      //  value: 5,
      //});
    } catch (err) {
      console.error(err);
      resolve();
    }
  });
};

export const reportSendNowClick = () => {
  return new Promise((resolve) => {
    try {
      window.gtag("event", "conversion", {
        send_to: "AW-631207360/YopUCNXE7t4DEMDr_awC",
        value: 5.0,
        currency: "ILS",
        event_callback: resolve,
      });
      setTimeout(resolve, 1500);
      //logEvent(window.analytics, "click_send_now", {
      //  value: 5,
      //});
    } catch (err) {
      console.error(err);
      resolve();
    }
  });
};

export const reportShowModal = async (title, text) => {
  try {
    window.ga(
      "send",
      "event",
      "show-modal",
      "title: " + title + " ,text: " + text
    );
  } catch (err) {
    console.error(err);
  }
};

export const LINK_TYPE = {
  APP_STORE: "APP_STORE",
  GOOGLE_PLAY: "GOOGLE_PLAY",
  MY_ORDERS: "MY_ORDERS",
  SEND_NOW: "SEND_NOW",
  WEB_APP_QR: "WEB_APP_QR",
};
export const getDynemicLink = (type, lang = "he") => {
  const map = {};
  let linkEnd = "";
  if (window.gclid) {
    linkEnd = "?gclid=" + window.gclid;
  }
  switch (type) {
    case LINK_TYPE.APP_STORE:
      map["link"] = "https://apps.apple.com/app/id1509656952";
      map["ibi"] = "com.bambizz.app";
      map["isi"] = "1509656952";
      map["utm_campaign"] = "App+Store+Button";
      map["utm_medium"] = "MAIN_APP_STORE";
      map["utm_source"] = "bambizz.com";
      break;
    case LINK_TYPE.GOOGLE_PLAY:
      map["link"] = "https://play.google.com/store/apps/details?id=com.bambizz";
      map["apn"] = "com.bambizz";
      map["utm_campaign"] = "Google+play+buttonn";
      map["utm_medium"] = "MAIN_GOOGLE_PLAY";
      map["utm_source"] = "bambizz.com";
      break;
    case LINK_TYPE.MY_ORDERS:
      map["link"] =
        `https://bambizz.com/${lang.toLowerCase()}/application/sender-my-orders` +
        linkEnd;
      map["apn"] = "com.bambizz";
      map["afl"] = "https://app.bambizz.com/sender-my-orders" + linkEnd;
      map["ifl"] = "https://app.bambizz.com/sender-my-orders" + linkEnd;
      map["isi"] = "1509656952";
      map["ibi"] = "com.bambizz.app";
      map["st"] = "במביז+קהילת+המשלוחים+שלך,+היעילות+היא+בזכותך";
      map["sd"] =
        "שלח+חבילה+במחיר+הטוב+ביותר+והמתאים+לך,+ובמהירות+שאתה+רוצה,+קבל+מגוון+הצעות+מחיר+ותחליט+מה+הכי+טוב+לך,+אצלנו+אתה+קובע+את+מחיר+המשלוח+שלך,+או+הצטרף+עוד+היום+לקהילת+השליחים+המרווחים+שלנו+ותוכל+להרוויח+כבר+היום+מכל+חבילה+באזור+שלך";
      map["si"] = "https://bambizz.com/images/screen_shot_he.png";
      map["utm_campaign"] = "Web+app";
      map["utm_medium"] = "MY_ORDERS";
      map["utm_source"] = "bambizz.com";
      break;
    case LINK_TYPE.SEND_NOW:
      map["link"] =
        `https://bambizz.com/${lang.toLowerCase()}/application/sender-input-order-1` +
        linkEnd;
      map["apn"] = "com.bambizz";
      map["afl"] = "https://app.bambizz.com/sender-input-order-1" + linkEnd;
      map["ifl"] = "https://app.bambizz.com/sender-input-order-1" + linkEnd;
      map["isi"] = "1509656952";
      map["ibi"] = "com.bambizz.app";
      map["st"] = "במביז+קהילת+המשלוחים+שלך,+היעילות+היא+בזכותך";
      map["sd"] =
        "שלח+חבילה+במחיר+הטוב+ביותר+והמתאים+לך,+ובמהירות+שאתה+רוצה,+קבל+מגוון+הצעות+מחיר+ותחליט+מה+הכי+טוב+לך,+אצלנו+אתה+קובע+את+מחיר+המשלוח+שלך,+או+הצטרף+עוד+היום+לקהילת+השליחים+המרווחים+שלנו+ותוכל+להרוויח+כבר+היום+מכל+חבילה+באזור+שלך";
      map["si"] = "https://bambizz.com/images/screen_shot_he.png";
      map["utm_campaign"] = "Web+app";
      map["utm_medium"] = "SEND_NOW";
      map["utm_source"] = "bambizz.com";
      break;
    case LINK_TYPE.WEB_APP_QR:
      map["link"] = "https://bambizz.com/";
      map["ibi"] = "com.bambizz.app";
      map["isi"] = "1509656952";
      map["apn"] = "com.bambizz";
      map["utm_campaign"] = "Propt+in+web+app";
      map["utm_medium"] = "QR_CODE";
      map["utm_source"] = "bambizz.com";
      break;
  }
  if (window.gclid) {
    map["gclid"] = window.gclid;
  }
  console.log(
    type,
    `https://link.bambizz.com/?${Object.entries(map)
      .map((ent) => `${ent[0]}=${encodeURIComponent(ent[1])}`)
      .reduce((a, b) => `${a}&${b}`)}`
  );
  return `https://link.bambizz.com/?${Object.entries(map)
    .map((ent) => `${ent[0]}=${encodeURIComponent(ent[1])}`)
    .reduce((a, b) => `${a}&${b}`)}`;
};

export const ngramSimilarity = (input, text, ngramSize = 3) => {
  const ngramFunc = nGram(ngramSize);
  const inputWords = ngramFunc(input.toLowerCase());
  const textWords = ngramFunc(text.toLowerCase());
  return lcs(textWords, inputWords) / inputWords.length || 0;
};

export const lcs = (X, Y) => {
  let i,
    j,
    L = [];
  for (i = 0; i <= X.length; i++) {
    L[i] = [];
    for (j = 0; j <= Y.length; j++) L[i].push(Infinity);
  }

  for (i = 0; i <= X.length; i++) {
    for (j = 0; j <= Y.length; j++) {
      if (i === 0 || j === 0) L[i][j] = 0;
      else if (X[i - 1] === Y[j - 1]) L[i][j] = L[i - 1][j - 1] + 1;
      else L[i][j] = max(L[i - 1][j], L[i][j - 1]);
    }
  }
  return L[X.length][Y.length];
};

/* Utility function to get max of 2 integers */
const max = (a, b) => {
  return a > b ? a : b;
};
